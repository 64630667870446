import React from 'react'
import BuyNowButton from './BuyNowButton'
import soldOut from '../../../assets/soldout.png'
import lastCall from '../../../assets/lastcall.svg'
import endSoon from '../../../assets/endsoon.png'
import gempadIcon from '../../../assets/gempad.png'

function FundraiseCard({ title, isLastCall=false, isSoldOut=false, isOfferEndSoon=false, price, total, detailLink, buyNowLink, date, gempad }) {
  return (
    <div className='card'>
      {isLastCall && (
        <img src={lastCall} alt='last call' width={100} style={{
          position: 'absolute',
          top: 10,
          left: 75
        }} />
      )}
      <div className='cardHead'>
        <h5>{title}</h5>
        {/* <p>{t('translation:investors')}</p> */}
      </div>
      {gempad && (
          <img src={gempadIcon} alt='gempad' width={100} style={{
            position: 'absolute',
            top: 71,
            left: 70,
          }} />
        )}
      {isSoldOut && (
        <img src={soldOut} alt='sold out' width={200} style={{
          position: 'absolute',
          top: 100,
          right: 20,
        }} />
      )}
      {isOfferEndSoon && (
        <img src={endSoon} alt='offer end soon' width={200} style={{
          position: 'absolute',
          top: 100,
          right: 20,
        }} />
      )}
      <div className='cardContent'>
        <h6 style={{
          marginTop: 40
        }}>1 PROMO = {price} USD</h6>
        <p style={{
          marginTop: 20,
          fontSize: 18,
          fontWeight: 300
        }}>
          Total Raise
        </p>
        <p style={{
          fontSize: 24,
          fontWeight: 600,
          marginTop: '-15px'
        }}>
          {total} USD
        </p>
        {detailLink && (<a href={detailLink} style={{
          display: 'block',
          textDecoration: 'none',
          fontSize: 16,
          fontWeight: 400,
          margin: '0 40px',
          border: '2px solid #D8D8D870',
          color: '#FFFFFF70',
          borderRadius: 10,
          marginTop: '-15px',
          position: 'relative',
          zIndex: 1000
        }}>See Details</a>)

        }
        <p style={{
          position: 'absolute',
          bottom: 20,
          left: 'calc(50% - 50px)',
          fontSize: 18,
          fontWeight: 300
        }}><b>Date:</b> {date}</p>
      </div>
      <BuyNowButton to={buyNowLink} soldOut={isSoldOut} />
    </div>
  )
}

export default FundraiseCard