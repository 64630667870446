import { useTranslation } from 'react-i18next'
import Slider from 'react-slick'
import MediaLogo from './MediaLogo'
import Line from '../../general/Line'

//Media Logo group 1
import GoogleNews from '../../../assets/promodexOnMedia/Google-news-250x125.png'
import Morning_Tick from '../../../assets/promodexOnMedia/morning_tick-250x125.png'
import Accesswire from '../../../assets/promodexOnMedia/AW_ISDR_Company.png'
import SNNNews from '../../../assets/promodexOnMedia/snn.png'
import Nebra from '../../../assets/promodexOnMedia/nebraska.png'
import Wral from '../../../assets/promodexOnMedia/Wral-Logo_75px.png'
import CoinTelegraph from '../../../assets/promodexOnMedia/CoinTelegraph.png'
import Business_Insider from '../../../assets/promodexOnMedia/business-insider.png'
import IbTimes from '../../../assets/promodexOnMedia/ibtimes.png'
import AssociatedPress from '../../../assets/promodexOnMedia/Associated_press_1-250x125.png'
import DigitalJournal from '../../../assets/promodexOnMedia/Digital-Journal-250x125.png'
import YahooFinance from '../../../assets/promodexOnMedia/Yahoo-Finance.jpg'

//Media Logo group 2
import UzmanCoin from '../../../assets/promodexOnMedia/Uzmancoin_logo_250x125.png'
import CoinKolik from '../../../assets/promodexOnMedia/Coinkolik_loog_250x125.png'
import CoinTurk from '../../../assets/promodexOnMedia/Coin-Turk_logo_250x125.png'
import BtcHaber from '../../../assets/promodexOnMedia/BTChaber_logo_250x125.png'
import KriptoKoin from '../../../assets/promodexOnMedia/kriptokoin.png'
import ilkbitcoin from '../../../assets/promodexOnMedia/ilkbitcoin.png'
import BTC_Manager from '../../../assets/promodexOnMedia/BTC-Manager-250x125.png'
import ZyCrypto from '../../../assets/promodexOnMedia/zycrypto250x125.png'
import CoinPedia from '../../../assets/promodexOnMedia/Coinpedia_logo_250x125.png'
import CoinGape from '../../../assets/promodexOnMedia/Coingape_logo_250x125.png'

import { useWindowWidth } from '../../utils/useWindowWidth'
const OnMedia = () => {
  const { t } = useTranslation()
  const innerWidth = useWindowWidth()
  const slides = () => {
    if (innerWidth >= 1200) {
      return 7
    }
    if (innerWidth >= 812) {
      return 5
    }
    if (innerWidth >= 500) {
      return 3
    } else {
      return 2
    }
  }
  const settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    speed: 700,
    slidesToShow: slides(),
    slidesToScroll: 1,
    arrows: false,
  }

  return (
    <section className='onMedia'>
      <h3>{t('translation:promodexOnMedia')}</h3>
      <Line />
      <div className='container'>
        <Slider {...settings}>
          <MediaLogo
            img={GoogleNews}
            link='https://news.google.com/search?q=promodex&hl=en-US&gl=US&ceid=US%3Aen'
          />
          <MediaLogo
            img={Morning_Tick}
            link='hhttps://morningtick.com/news/promodex-io-uses-blockchain-technology-to-revolutionize-influencer-marketing'
          />
          <MediaLogo
            img={Accesswire}
            link='https://www.accesswire.com/677271/Promodex-Aims-to-Revolutionize-Influencer-Marketing-More-Programmatic-and-Decentralized'
          />
          <MediaLogo
            img={SNNNews}
            link='https://www.snntv.com/story/45437090/promodex-aims-to-revolutionize-influencer-marketing-more-programmatic-and-decentralized'
          />
          <MediaLogo
            img={Nebra}
            link='https://central.newschannelnebraska.com/story/45437090/promodex-aims-to-revolutionize-influencer-marketing-more-programmatic-and-decentralized'
          />
          <MediaLogo
            img={Wral}
            link='https://markets.financialcontent.com/wral/news/read/42050335/promodex_aims_to_revolutionize_influencer_marketing_more_programmatic_and_decentralized'
          />
          <MediaLogo
            img={CoinTelegraph}
            link='https://tr.cointelegraph.com/press-releases/promodex-has-announced-its-public-private-sale-whitelist-campaign-and-dates'
          />
          <MediaLogo
            img={Business_Insider}
            link='https://markets.businessinsider.com/news/stocks/promodex-aims-to-revolutionize-influencer-marketing-more-programmatic-and-decentralized-1031042211'
          />
          <MediaLogo
            img={IbTimes}
            link='https://markets.financialcontent.com/ibtimes/news/read/42050335'
          />
          <MediaLogo
            img={AssociatedPress}
            link='https://apnews.com/press-release/accesswire/technology-business-media-social-media-blockchain-fc05ea86de53bbea228da7d495e87133'
          />
          <MediaLogo
            img={DigitalJournal}
            link='https://www.digitaljournal.com/pr/promodex-aims-to-revolutionize-influencer-marketing-more-programmatic-and-decentralized'
          />
          <MediaLogo
            img={YahooFinance}
            link='https://finance.yahoo.com/news/promodex-aims-revolutionize-influencer-marketing-181500479.html?guccounter=1&guce_referrer=aHR0cHM6Ly9wcm9tb2RleC5pby8&guce_referrer_sig=AQAAAG2YxzNTJgq6Q2p_S6xS99uKJAF5qR5f_nzwZpx4xRPiEbyhvWGorx3OcJGEgxVV59wGTpvuG7AAx-TMuYZx9F1-UF0GqWgvuYpwjoraWXc9swsQIVQPcWChiyEdT-lbwK2lCc8Q5ginyRfuZBmf1qaKH3j3SXlP8NmuzYbkWIBw'
          />
        </Slider>
      </div>

      <div className='container'>
        <Slider {...settings}>
          <MediaLogo
            img={UzmanCoin}
            link='https://uzmancoin.com/promodex-fonlama-sureci-ile-influencer-marketing-2-0-hayata-geciyor'
          />
          <MediaLogo
            img={CoinKolik}
            link='https://www.coinkolik.com/promodex-fonlama-sureci-ile-influencer-marketing-2-0-hayata-geciyor/'
          />
          <MediaLogo
            img={CoinTurk}
            link='https://coin-turk.com/promodex-fonlama-sureci-ile-influencer-marketing-2-0-hayata-geciyor'
          />
          <MediaLogo
            img={BtcHaber}
            link='https://www.btchaber.com/promodex-fonlama-sureci-ile-influencer-marketing-2-0hayata-geciyor/'
          />
          <MediaLogo
            img={KriptoKoin}
            link='https://kriptokoin.com/bol-odullu-kampanyasiyla-promodex-tam-gaz'
          />
          <MediaLogo
            img={ilkbitcoin}
            link='https://ilkbitcoin.com/promodex-fonlama-sureci-ile-influencer-marketing-2-0-hayata-geciyor/'
          />
          <MediaLogo
            img={BTC_Manager}
            link='https://crypto.news/promodex-io-influencer-marketing-2-0-platform-airdrop-whitelist-campaigns/'
          />
          <MediaLogo
            img={ZyCrypto}
            link='https://zycrypto.com/promodex-io-influencer-marketing-2-0-platform-launched-airdrop-and-whitelist-campaigns/'
          />
          <MediaLogo
            img={CoinPedia}
            link='https://coinpedia.org/sponsored/promodex-airdrop-and-whitelist/'
          />
          <MediaLogo
            img={CoinGape}
            link='https://coingape.com/promodex-io-influencer-marketing-2-0-platform-launched-airdrop-and-whitelist-campaigns/'
          />
        </Slider>
      </div>
    </section>
  )
}

export default OnMedia
