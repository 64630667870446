import Introduction from './introduction/Introduction'
import About from './about/About'
import HowItWorks from './howItWorks/HowItWorks'
import Features from './features/Features'
import Roadmap from './roadmap/Roadmap'
import Token from './token/Token'
import Team from './team/Team'
import Partners from './partners/Partners'
import Influencers from './influencers/Influencers'
import OnMedia from './onMedia/OnMedia'

const Main = () => {
  return (
    <main>
      <Introduction />
      <About />
      <HowItWorks />
      <Features />
      <Roadmap />
      <Token />
      <Team />
      <hr color='lightgray' />
      <Partners />
      <hr color='lightgray' />
      <Influencers />
      <hr color='lightgray' />
      <OnMedia />
      <hr color='#6B03FE' />
    </main>
  )
}

export default Main
