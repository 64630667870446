import React from 'react'

function BuyNowButton({ to, soldOut, label='Buy Now' }) {
  if (soldOut) return (
    <span style={{
      width: 150,
      height: 40,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      bottom: -20,
      left: 'calc(50% - 75px)',
      color: '#FFFFFF',
      background: 'linear-gradient(0deg, #000659 0%, rgba(83,64,255,0.75) 86%)',
      border: '1px solid #FFFFFF',
      borderRadius: '4px'
    }}>Sold Out</span>
  )
  return (
    <a href={to} target='_blank' style={{
      width: 150,
      height: 40,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      bottom: -20,
      left: 'calc(50% - 75px)',
      color: '#FFFFFF',
      background: 'linear-gradient(0deg, #000659 0%, rgba(83,64,255,0.75) 86%)',
      border: '1px solid #FFFFFF',
      borderRadius: '4px'
    }} rel="noreferrer">{label}</a>
  )
}

export default BuyNowButton