import { dataSource } from './pieChartData'
import { PieChart } from 'react-minimal-pie-chart'
import Line from '../../general/Line'
import { useTranslation } from 'react-i18next'
import { BsArrowRightShort } from 'react-icons/bs'
import { RiRadioButtonFill } from 'react-icons/ri'
import UpcomingFundraise from './UpcomingFundraise'

const Token = () => {
  const { t } = useTranslation()
  return (
    <section id='token' className='token'>
      <div className='tokenHead'>
        <h3>{t('translation:tokenomics')}</h3>
        <Line />
      </div>
      <div className='tokenContainer'>
        <div className='tokenContent'>
          <h6>{t('translation:promodexUtilityToken')}: $PROMO</h6>
          <h6>{t('translation:totalSupply')}:</h6>
          <h6>900.000.000 PROMO</h6>
          <p>
            <strong>{t('translation:utilizedFor')};</strong>
          </p>
          <ul className='contentList'>
            <li className='contentList'>
              <BsArrowRightShort className='arrowIcon' />
              <span> {t('translation:campaign')}</span>
            </li>
            <li className='contentList'>
              <BsArrowRightShort className='arrowIcon' />
              <span>{t('translation:staking')}</span>
            </li>
            <li className='contentList'>
              <BsArrowRightShort className='arrowIcon' />
              <span>{t('translation:campaignPart')}</span>
            </li>
            <li className='contentList'>
              <BsArrowRightShort className='arrowIcon' />
              <span>{t('translation:higher')}</span>
            </li>
            <li className='contentList'>
              <BsArrowRightShort className='arrowIcon' />
              <span>{t('translation:stakingRewards')}</span>
            </li>
          </ul>
        </div>
        <div className='chartAndLabels'>
          <div className='tokenChart'>
            <PieChart
              data={dataSource}
              lineWidth={50}
              totalValue={100}
              startAngle={-90}
              animate={true}
              animationDuration={1000}
              animationEasing={'ease-out'}
            />
          </div>
          <div className='tokenLabels'>
            <ul>
              {dataSource.map((data) => (
                <li key={data.title}>
                  <RiRadioButtonFill style={{ color: `${data.color}` }} />
                  {data.title}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
      <UpcomingFundraise />
    </section>
  )
}

export default Token
