import i18n from '../../languages/i18n'

const SelectLanguage = () => {
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng)
    localStorage.setItem('multilang', lng)
  }
  return (
    <div className='lang'>
      <select
        name='language'
        className='selectInput'
        id='language'
        onChange={(event) => changeLanguage(event.target.value)}
      >
        <option value='en'>🇺🇸 English</option>
        <option value='tr'>🇹🇷 Türkçe</option>
      </select>
    </div>
  )
}

export default SelectLanguage
