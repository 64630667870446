import { useTranslation } from 'react-i18next'
import Line from '../../general/Line'
import howItWorksImage from '../../../assets/gifs/howItWorks2.gif'
import { BsArrowRightShort } from 'react-icons/bs'
import { FaBullhorn, FaWallet, FaCoins } from 'react-icons/fa'

const PrimaryFunctions = () => {
  const { t } = useTranslation()
  return (
    <div className='primaryFuncContainer'>
      <div className='primaryFuncHead'>
        <h3>{t('translation:primaryFunctions')}</h3>
        <span>{t('translation:primaryFunctionsSmall')}</span>
        <Line />
        <div className='primaryFuncGif'>
          <img src={howItWorksImage} alt='Promodex flow chart' />
        </div>
      </div>
      <div className='primaryFuncList'>
        <div className='equalFlexBox'>
          <h6>
            {' '}
            <FaBullhorn />
            {t('translation:campaignLaunch')}
          </h6>
          <ul>
            <li className='whyListItem'>
              <BsArrowRightShort className='arrowIcon' />
              <span>{t('translation:primaryFunctionsListItem1')}</span>
            </li>
            <li className='whyListItem'>
              <BsArrowRightShort className='arrowIcon' />
              <span>{t('translation:primaryFunctionsListItem2')}</span>
            </li>
            <li className='whyListItem'>
              <BsArrowRightShort className='arrowIcon' />
              <span>{t('translation:primaryFunctionsListItem3')}</span>
            </li>
          </ul>
        </div>
        <div className='equalFlexBox'>
          <h6>
            <FaWallet />
            {t('translation:tokenLocking')}
          </h6>
          <ul>
            <li className='whyListItem'>
              <BsArrowRightShort className='arrowIcon' />
              <span>{t('translation:primaryFunctionsListItem4')}</span>
            </li>
            <li className='whyListItem'>
              <BsArrowRightShort className='arrowIcon' />
              <span>{t('translation:primaryFunctionsListItem5')}</span>
            </li>
            <li className='whyListItem'>
              <BsArrowRightShort className='arrowIcon' />
              <span>{t('translation:primaryFunctionsListItem6')}</span>
            </li>
          </ul>
        </div>
        <div className='equalFlexBox'>
          <h6>
            <FaCoins />
            {t('translation:pdoLaunch')}
          </h6>
          <ul>
            <li className='whyListItem'>
              <BsArrowRightShort className='arrowIcon' />
              <span>{t('translation:primaryFunctionsListItem7')}</span>
            </li>
            <li className='whyListItem'>
              <BsArrowRightShort className='arrowIcon' />
              <span>{t('translation:primaryFunctionsListItem8')}</span>
            </li>
            <li className='whyListItem'>
              <BsArrowRightShort className='arrowIcon' />
              <span>{t('translation:primaryFunctionsListItem9')}</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export default PrimaryFunctions
