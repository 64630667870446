export const dataSource = [
  {
    title: 'Seed Fund 1% - 9M',
    value: 2,
    color: '#CCEEF9',
  },
  {
    title: 'PreSale 2% - 18M',
    value: 2,
    color: '#FFFFAA',
  },
  {
    title: 'Strategic Round 6% - 36M',
    value: 5,
    color: '#FFC6F9',
  },
  {
    title: 'Private Sale 1% - 9M',
    value: 1,
    color: '#8F45BC',
  },
  {
    title: 'Public Sale 4% - 36M',
    value: 2,
    color: '#FFECC0',
  },
  {
    title: 'Advisors 1% - 9M',
    value: 3,
    color: '#FFC5C1',
  },
  {
    title: 'Team 15% - 135M',
    value: 15,
    color: '#80EEFF',
  },
  {
    title: 'Marketing & Ecosystem 20% - 180M',
    value: 20,
    color: '#78C57F',
  },
  {
    title: 'Liquidity 15% - 135M',
    value: 15,
    color: '#80C9FF',
  },
  {
    title: 'Staking Rewards 25% - 225M',
    value: 25,
    color: '#FF4F8F',
  },
  {
    title: 'Reserve 10% - 90M',
    value: 10,
    color: '#7365FF',
  },
]
