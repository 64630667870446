import { useState, useRef } from 'react'
import OutsideClick from '../utils/OutsideClick'
import { IoChevronDownOutline } from 'react-icons/io5'
const Dropdown = () => {
  const [toggle, setToggle] = useState(false)
  const toggleRef = useRef()
  const handleToggle = (e) => {
    e.preventDefault()
    setToggle(!toggle)
  }
  const handleClickOutside = () => {
    setToggle(false)
  }

  OutsideClick(handleClickOutside, toggleRef)
  return (
    <div className='dropdown'>
      <button onClick={handleToggle}>
        Community <IoChevronDownOutline />
      </button>
      {toggle && (
        <div className='dropMenu' ref={toggleRef}>
          <ul>
            <li>
              <a
                href='https://t.me/promodexio_global'
                target='_blank'
                rel='noreferrer'
              >
                Community
              </a>
            </li>
            <li>
              <a
                href='https://t.me/promodexio'
                target='_blank'
                rel='noreferrer'
              >
                Announcement
              </a>
            </li>
          </ul>
        </div>
      )}
    </div>
  )
}

export default Dropdown
