import { FaTwitter, FaMedium, FaYoutube, FaLinkedinIn } from 'react-icons/fa'
import Dropdown from './Dropdown'
const Community = () => {
  return (
    <div className='community'>
      <div className='socialIcons'>
        <a
          href='https://twitter.com/promodexio'
          target='_blank'
          rel='noreferrer'
        >
          <FaTwitter />
        </a>
        <a href='https://promodex.medium.com/' target='_blank' rel='noreferrer'>
          <FaMedium />
        </a>
        <a
          href='https://www.youtube.com/channel/UCEtCnSnFlHqgB6woV4HVP4w'
          target='_blank'
          rel='noreferrer'
        >
          <FaYoutube />
        </a>
        <a
          href='https://www.linkedin.com/company/promodexio/'
          target='_blank'
          rel='noreferrer'
        >
          <FaLinkedinIn />
        </a>
      </div>
      <Dropdown />
    </div>
  )
}

export default Community
