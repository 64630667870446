import PrimaryFunctions from './PrimaryFunctions'
import WhyPromodex from './WhyPromodex'

const HowItWorks = () => {
  return (
    <section id='howItWorks' className='howItWorks'>
      <WhyPromodex />
      <PrimaryFunctions />
    </section>
  )
}

export default HowItWorks
