import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Footer from './components/footer/Footer'
import CookiesPolicy from './components/footer/policies/CookiesPolicy'
import PrivacyPolicy from './components/footer/policies/PrivacyPolicy'
import UserAgreement from './components/footer/policies/UserAgreement'
import FloatingButtonMenu from './components/general/FloatingButtonMenu'
import Header from './components/header/Header'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
//pages
import Home from './pages/Home'

//stylig
import './sass/App.scss'

const App = () => {
  return (
    <div className='App'>
      <BrowserRouter>
        <Header />
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/privacy-policy' element={<PrivacyPolicy />} />
          <Route path='/cookies-policy' element={<CookiesPolicy />} />
          <Route path='/user-agreement' element={<UserAgreement />} />
        </Routes>
        <FloatingButtonMenu />
        <Footer />
      </BrowserRouter>
    </div>
  )
}

export default App
