import WhatIsPromodex from './WhatIsPromodex'

const About = () => {
  return (
    <section id='aboutPromo' className='about'>
      <WhatIsPromodex />
    </section>
  )
}
export default About
