import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import Logo from '../../assets/logo/logo.png'
import QuickLinks from './QuickLinks'
import RecentPost from './RecentPost'
import SocialIcons from './SocialIcons'

const handleScrollY = () => {
  window.scrollTo(0, 0)
}
const Footer = () => {
  const { t } = useTranslation()
  return (
    <footer>
      <div className='footer'>
        <div className='logo'>
          <img src={Logo} alt='logo' />
          <p>{t('translation:footerParagraph')}</p>
        </div>
        <RecentPost />
        <QuickLinks />
        <SocialIcons />
      </div>
      <div className='policiesLinks'>
        <Link to='/privacy-policy' onClick={handleScrollY}>
          Privacy Policy
        </Link>
        <Link to='/cookies-policy' onClick={handleScrollY}>
          Cookies Policy
        </Link>
        <Link to='/user-agreement' onClick={handleScrollY}>
          User Agreement
        </Link>
      </div>
      <p className='copyright'>
        &copy; Copyright 2021 Promodex powered by{' '}
        <a href='https://devtagon.com/' target='_blank' rel='noreferrer'>
          Devtagon
        </a>
      </p>
    </footer>
  )
}

export default Footer
