import React from 'react'
import { useTranslation } from 'react-i18next'

const QuickLinks = () => {
  const { t } = useTranslation()
  return (
    <div className='quickLinks'>
      <h3>{t('translation:quickLinks')}</h3>
      <div className='linkbox'>
        <ul>
          <li>
            <a
              href='https://linktr.ee/promodexio'
              target='_blank'
              rel='noreferrer'
            >
              {t('translation:LinkTree')}
            </a>
          </li>
          <li>
            <a
              href='https://drive.google.com/drive/folders/1QtLU-wYtNuPwC9UT3fTkM9SHOQffg5-0'
              target='_blank'
              rel='noreferrer'
            >
              {t('translation:mediaKit')}
            </a>
          </li>
          <li>
            <a
              href='https://drive.google.com/file/d/1-Ycoi98Y2--sZDRfhnOgbQI92hHn2uDa/view'
              target='_blank'
              rel='noreferrer'
            >
              {t('translation:whitepaper')}
            </a>
          </li>
          <li>
            <a
              href='https://drive.google.com/file/d/1PRmiRd-bfojkq4IVoQMSfznASqIn2asD/view'
              target='_blank'
              rel='noreferrer'
            >
              {t('translation:onepager')}
            </a>
          </li>
          <li>
            <a
              href='https://drive.google.com/file/d/12SVFEfT0VaJjAonmWQUsxqoLWT78yANR/view'
              target='_blank'
              rel='noreferrer'
            >
              {t('translation:tokenomics')}
            </a>
          </li>
        </ul>
        <ul>
          <li>
            <a
              href='https://t.me/promodexio_global'
              target='_blank'
              rel='noreferrer'
            >
              {t('translation:community')}
            </a>
          </li>
          <li>
            <a
              href='https://t.me/promodexio'
              target='_blank'
              rel='noreferrer'
            >
              {t('translation:announcement')}
            </a>
          </li>
          <li>
            <a
              href='https://drive.google.com/file/d/122IgU1n12w7ZIqjj3CQ_pMHGFEkMswGQ/view'
              target='_blank'
              rel='noreferrer'
            >
              {t('translation:presentation')}
            </a>
          </li>
          <li>
            <a
              href='https://promodex.medium.com/'
              target='_blank'
              rel='noreferrer'
            >
              {t('translation:mediumBlog')}
            </a>
          </li>
          <li>
            <a
              href='emailto:support@promodex.io'
              target='_blank'
              rel='noreferrer'
            >
              {t('translation:support')}
            </a>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default QuickLinks
