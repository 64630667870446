import Slider from 'react-slick'
import { BiMedal } from 'react-icons/bi'
import InfluencerProfile from './InfluencerProfile'

//Profil photos group 1
import Expert_Kripto from '../../../assets/influencers/tr/Expert-Kripto-.png'
import MehmetH_Tufan from '../../../assets/influencers/tr/Mehmet-H.-Tufan-1.png'
import Coin_Delisi from '../../../assets/influencers/tr/Coin-Delisi.png'
import Rookie from '../../../assets/influencers/tr/Rookie-1.png'
import Kripto_Dayi from '../../../assets/influencers/tr/Kripto-Dayi-2.png'
import Filiz from '../../../assets/influencers/tr/Filiz-1.png'
import Eralp_Buyukaslan from '../../../assets/influencers/tr/Eralp-Büyükaslan-1.png'
import Ahmet_Elbistanli from '../../../assets/influencers/tr/Ahmet-Elbistanli-1.png'
import Crypto_Erge from '../../../assets/influencers/tr/Erge-aka-The-Sniper.png'
import Crypto_Legolas from '../../../assets/influencers/tr/Crypto-Legolas.png'
import Nihat_NY from '../../../assets/influencers/tr/Nihat-Doğublock.png'
import OnurSuBrk from '../../../assets/influencers/tr/Onur-BARIK.png'

//Profil photos group 2
import Kemal from '../../../assets/influencers/tr/ƘємαƖ.png'
import ItCol from '../../../assets/influencers/tr/lt.col_.temmuz.png'
import Coin_Terbiyecisi from '../../../assets/influencers/tr/Coin-Terbiyecisi.png'
import Hakan_Hanoglu from '../../../assets/influencers/tr/HANOGLU-Hakan.png'
import AzzBhtyr from '../../../assets/influencers/tr/azzbhtyr.png'
import Teknikci from '../../../assets/influencers/tr/Teknikçi.png'
import Jamiyro from '../../../assets/influencers/tr/Jamiryo-BTC.png'
import Wind_Crypto from '../../../assets/influencers/tr/Wind-Crypto.png'
import Muhammed from '../../../assets/influencers/tr/Muhammed.png'
import Can_Ozsuer from '../../../assets/influencers/tr/Can-Özsüer.png'
import Devre_Kesici from '../../../assets/influencers/tr/DEVRE-KESİCİ-YAVUZ.png'
import Hala_Ogreniyorum from '../../../assets/influencers/tr/hala-öğreniyorum.png'
import Antonio from '../../../assets/influencers/tr/Antonio-Vivaldi.png'
import Paramatik from '../../../assets/influencers/tr/Paramatik.png'
import Detroitli from '../../../assets/influencers/tr/Detroitli-Kizil.png'
import Zeyna from '../../../assets/influencers/tr/Zeyna.png'

//Profil photos group 3
import Bitcoin_Meraklisi from '../../../assets/influencers/tr/Bitcoin-Meraklisi.png'
import CoinAstra from '../../../assets/influencers/tr/CoinAstra.png'
import Crypto_Plus from '../../../assets/influencers/tr/Crypto-Plus.png'
import Crypto_Legend from '../../../assets/influencers/tr/Crypto-Legend.png'
import Crypto_G from '../../../assets/influencers/tr/Crypto-G.png'
import Adamin_Biri from '../../../assets/influencers/tr/Adamin-Biri.png'
import Bullish from '../../../assets/influencers/tr/BULLİSH-MAN.png'
import Crypto_Toria from '../../../assets/influencers/tr/Crypto-Troia.png'
import Kripto_Joker from '../../../assets/influencers/tr/Kripto-Joker.png'
import Siesta_Laila from '../../../assets/influencers/tr/siesta_laila.png'
import Trader_Ali from '../../../assets/influencers/tr/TRADER-ALİ-F.png'
import { useWindowWidth } from '../../utils/useWindowWidth'
const Influencers = () => {
  const innerWidth = useWindowWidth()
  const slides = () => {
    if (innerWidth >= 1200) {
      return 8
    }
    if (innerWidth >= 812) {
      return 5
    }
    if (innerWidth >= 500) {
      return 4
    } else {
      return 3
    }
  }
  const settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    speed: 600,
    slidesToShow: slides(),
    slidesToScroll: 1,
    arrows: false,
  }
  const settings2 = {
    dots: false,
    infinite: true,
    autoplay: true,
    speed: 1000,
    slidesToShow: slides(),
    slidesToScroll: 1,
    arrows: false,
  }
  return (
    <section className='influencers'>
      <h3>Lead & Associate Influencers</h3>
      <div className='applyButton'>
        <a href='https://docs.google.com/forms/d/e/1FAIpQLScMJgzGBUzxxSCgSh_0ISQr2FewhMjOy-GOitbhqBJIU2NoFg/viewform'>
          APPLY TO BE HERE
          <BiMedal />
        </a>
      </div>
      <div className='tabs'>
        <span>Turkish</span>
        <span>English</span>
        <span>French</span>
        <span>Russian</span>
        <span>Spanish</span>
        <span>Portuguese</span>
        <span>German</span>
        <span>Chinese</span>
        <span>Hindi</span>
        <span>Indonesian</span>
        <span>Korean</span>
        <span>Japanese</span>
        <span>Arabic</span>
        <span>Vietnamese</span>
      </div>
      <div className='container'>
        <Slider {...settings}>
          <InfluencerProfile
            img={Expert_Kripto}
            link='https://twitter.com/expert_kripto'
          />
          <InfluencerProfile
            img={MehmetH_Tufan}
            link='https://twitter.com/memethasantufan'
          />
          <InfluencerProfile
            img={Coin_Delisi}
            link='https://twitter.com/coindelisi'
          />
          <InfluencerProfile
            img={Rookie}
            link='https://twitter.com/altcoinrookie'
          />

          <InfluencerProfile
            img={Kripto_Dayi}
            link='https://twitter.com/KriptoDayii'
          />
          <InfluencerProfile
            img={Filiz}
            link='https://twitter.com/kurcenli34'
          />
          <InfluencerProfile
            img={Eralp_Buyukaslan}
            link='https://twitter.com/eralpbuyukaslan'
          />
          <InfluencerProfile
            img={Ahmet_Elbistanli}
            link='https://www.instagram.com/ahmetelbistanli/'
          />
          <InfluencerProfile
            img={Crypto_Erge}
            link='https://twitter.com/CryptoErge'
          />
          <InfluencerProfile
            img={Crypto_Legolas}
            link='https://twitter.com/CryptoLego0311'
          />
          <InfluencerProfile
            img={Nihat_NY}
            link='https://twitter.com/Nihat__NY'
          />
          <InfluencerProfile
            img={OnurSuBrk}
            link='https://twitter.com/OnurSuBrk'
          />
        </Slider>
      </div>
      <div className='container'>
        <Slider {...settings2}>
          <InfluencerProfile
            img={Kemal}
            link='https://twitter.com/BTC_USDT_LTC'
          />
          <InfluencerProfile
            img={ItCol}
            link='https://twitter.com/ItAgustos1071'
          />
          <InfluencerProfile
            img={Coin_Terbiyecisi}
            link='https://twitter.com/CoinTerbiyecis'
          />
          <InfluencerProfile
            img={Hakan_Hanoglu}
            link='https://twitter.com/hanoglu_hakan'
          />
          <InfluencerProfile
            img={AzzBhtyr}
            link='https://twitter.com/azzbhtyr'
          />
          <InfluencerProfile
            img={Teknikci}
            link='https://twitter.com/teknikciniz'
          />
          <InfluencerProfile
            img={Jamiyro}
            link='https://twitter.com/ercekripto'
          />
          <InfluencerProfile
            img={Wind_Crypto}
            link='https://twitter.com/Wind_Crypto'
          />
          <InfluencerProfile
            img={Muhammed}
            link='https://twitter.com/ernealist'
          />
          <InfluencerProfile
            img={Can_Ozsuer}
            link='https://twitter.com/canozsuerx'
          />
          <InfluencerProfile
            img={Devre_Kesici}
            link='https://twitter.com/DevreKesici'
          />
          <InfluencerProfile
            img={Hala_Ogreniyorum}
            link='https://twitter.com/knowledge3307'
          />
          <InfluencerProfile
            img={Antonio}
            link='https://twitter.com/Antonyo_Vivaldi'
          />
          <InfluencerProfile
            img={Paramatik}
            link='https://twitter.com/Coinmatik1'
          />
          <InfluencerProfile
            img={Detroitli}
            link='https://twitter.com/detroitlikizil'
          />
          <InfluencerProfile
            img={Zeyna}
            link='https://twitter.com/zeynepdenbiri'
          />
        </Slider>
      </div>
      <div className='container'>
        <Slider {...settings}>
          <InfluencerProfile
            img={Bitcoin_Meraklisi}
            link='https://twitter.com/Bitcoinmeraklsi'
          />
          <InfluencerProfile
            img={CoinAstra}
            link='https://twitter.com/CoinAstra'
          />
          <InfluencerProfile
            img={Crypto_Plus}
            link='https://twitter.com/cryptoplustr'
          />
          <InfluencerProfile
            img={Crypto_Legend}
            link='https://twitter.com/cryptolegennd'
          />

          <InfluencerProfile
            img={Crypto_G}
            link='https://twitter.com/Crypto_G__'
          />
          <InfluencerProfile
            img={Adamin_Biri}
            link='https://twitter.com/cryptonerium'
          />
          <InfluencerProfile
            img={Bullish}
            link='https://twitter.com/BULLISHMANN'
          />
          <InfluencerProfile
            img={Crypto_Toria}
            link='https://www.instagram.com/CryptoTroia'
          />
          <InfluencerProfile
            img={Kripto_Joker}
            link='https://twitter.com/Jkr_Crypto'
          />
          <InfluencerProfile
            img={Siesta_Laila}
            link='https://twitter.com/siesta_laila'
          />
          <InfluencerProfile
            img={Trader_Ali}
            link='https://twitter.com/TRADERxALI'
          />
        </Slider>
      </div>
    </section>
  )
}

export default Influencers
