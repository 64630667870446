import { useTranslation } from 'react-i18next'
import Line from '../../general/Line'
import { BsArrowRightShort } from 'react-icons/bs'
import { FaBullhorn, FaBlog } from 'react-icons/fa'
import WhyPromodexImage from '../../../assets//why-promodex.svg'
const WhyPromodex = () => {
  const { t } = useTranslation()
  return (
    <div className='why'>
      <div className='whyContent'>
        <h3 className='whyHeader'> {t('translation:whyPromodexTitle')} </h3>
        <span>{t('translation:whyPromodexSmall')}</span>
        <Line />
        <h5 className='whySubHeader'>
          {t('translation:whyPromodexDescription')}
        </h5>
        <h5 className='whySubHeader'>
          {t('translation:whyPromodexDescription2')}
        </h5>

        <div className='whyListBox'>
          <div className='listPart'>
            <h6>
              <FaBullhorn />
              {t('translation:campaigners')}
            </h6>
            <ul>
              <li className='whyListItem'>
                <BsArrowRightShort className='arrowIcon' />
                <span>{t('translation:whyPromodexListItem1')}</span>
              </li>
              <li className='whyListItem'>
                <BsArrowRightShort className='arrowIcon' />
                <span>{t('translation:whyPromodexListItem2')}</span>
              </li>
              <li className='whyListItem'>
                <BsArrowRightShort className='arrowIcon' />
                <span>{t('translation:whyPromodexListItem3')}</span>
              </li>
            </ul>
          </div>

          <div className='listPart'>
            <h6>
              <FaBlog />
              {t('translation:promoters')}
            </h6>
            <ul>
              <li className='whyListItem'>
                <BsArrowRightShort className='arrowIcon' />
                <span>{t('translation:whyPromodexListItem4')}</span>
              </li>
              <li className='whyListItem'>
                <BsArrowRightShort className='arrowIcon' />
                <span>{t('translation:whyPromodexListItem5')}</span>
              </li>
              <li className='whyListItem'>
                <BsArrowRightShort className='arrowIcon' />
                <span>{t('translation:whyPromodexListItem6')}</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className='whyImg'>
        <img src={WhyPromodexImage} alt='whyPromodexImage' width={400} />
      </div>
    </div>
  )
}

export default WhyPromodex
