import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { FaHandPointRight } from 'react-icons/fa'

const RecentPost = () => {
  const { t } = useTranslation()
  return (
    <div className='recentPost'>
      <h3>{t('translation:recentPosts')}</h3>
      <ul className='linkMenu'>
        <li>
          <FaHandPointRight />
          <Link to='https://medium.com/@promodex/promodex-tokenomics-f635869fab02'>{t('translation:recentPost1')}</Link>
        </li>
        <li>
          <FaHandPointRight />
          <Link to='https://medium.com/@promodex/what-is-promodex-and-what-does-it-aim-for-8abb4ef6d745'>{t('translation:recentPost2')}</Link>
        </li>
        <li>
          <FaHandPointRight />
          <Link to='https://medium.com/@promodex/why-would-you-prefer-promodex-to-other-platforms-to-launch-a-promotional-campaign-9de4ccaf84d5'>{t('translation:recentPost3')}</Link>
        </li>
        <li>
          <FaHandPointRight />
          <Link to='https://medium.com/@promodex/can-i-stake-promo-tokens-e26a19387ec8'>{t('translation:recentPost4')}</Link>
        </li>
        <li>
          <FaHandPointRight />
          <Link to='https://medium.com/@promodex/why-would-you-prefer-promodex-to-the-others-to-launch-bounty-and-airdrop-delivery-aeab8a284181'>{t('translation:recentPost5')}</Link>
        </li>
      </ul>
    </div>
  )
}

export default RecentPost
