import {
  FaSortAmountUp,
  FaColumns,
  FaRegEdit,
  FaLink,
  FaChartLine,
  FaFingerprint,
  FaHandHoldingUsd,
  FaUsers,
} from 'react-icons/fa'
import { HiOutlineCalculator } from 'react-icons/hi'
import { useTranslation } from 'react-i18next'
import Line from '../../general/Line'
import { BsSpeedometer2 } from 'react-icons/bs'
const Features = () => {
  const { t } = useTranslation()
  return (
    <section id='features' className='features'>
      <div className='featuresHead'>
        <div>
          <h3>{t('translation:features')}</h3>
          <Line />
        </div>
        <div></div>
      </div>
      <div className='cards'>
        <div className='card'>
          <div className='cardIcon'>
           <BsSpeedometer2 />
          </div>
          <div className='cardContent'>
            <h5 className='cardHead'>{t('translation:featuresCardTitle1')}</h5>
            <p className='cardText'>{t('translation:featuresCardText1')}</p>
          </div>
        </div>

        <div className='card'>
          <div className='cardIcon'>
            <FaUsers />
          </div>
          <div className='cardContent'>
            <h5 className='cardHead'>{t('translation:featuresCardTitle2')}</h5>
            <p className='cardText'>{t('translation:featuresCardText2')}</p>
          </div>
        </div>

        <div className='card'>
          <div className='cardIcon'>
            <FaColumns />
          </div>
          <div className='cardContent'>
            <h5 className='cardHead'>{t('translation:featuresCardTitle3')}</h5>
            <p className='cardText'>{t('translation:featuresCardText3')}</p>
          </div>
        </div>
        <div className='card'>
          <div className='cardIcon'>
            <FaRegEdit />
          </div>
          <div className='cardContent'>
            <h5 className='cardHead'>{t('translation:featuresCardTitle4')}</h5>
            <p className='cardText'>{t('translation:featuresCardText4')}</p>
          </div>
        </div>
        <div className='card'>
          <div className='cardIcon'>
            <FaLink />
          </div>
          <div className='cardContent'>
            <h5 className='cardHead'>{t('translation:featuresCardTitle5')}</h5>
            <p className='cardText'>{t('translation:featuresCardText5')}</p>
          </div>
        </div>
        <div className='card'>
          <div className='cardIcon'>
            <FaChartLine />
          </div>
          <div className='cardContent'>
            <h5 className='cardHead'>{t('translation:featuresCardTitle6')}</h5>
            <p className='cardText'>{t('translation:featuresCardText6')}</p>
          </div>
        </div>
        <div className='card'>
          <div className='cardIcon'>
            <FaFingerprint />
          </div>
          <div className='cardContent'>
            <h5 className='cardHead'>{t('translation:featuresCardTitle7')}</h5>
            <p className='cardText'>{t('translation:featuresCardText7')}</p>
          </div>
        </div>
        <div className='card'>
          <div className='cardIcon'>
            <HiOutlineCalculator />
          </div>
          <div className='cardContent'>
            <h5 className='cardHead'>{t('translation:featuresCardTitle8')}</h5>
            <p className='cardText'>{t('translation:featuresCardText8')}</p>
          </div>
        </div>
        <div className='card'>
          <div className='cardIcon'>
            <FaHandHoldingUsd />
          </div>
          <div className='cardContent'>
            <h5 className='cardHead'>{t('translation:featuresCardTitle9')}</h5>
            <p className='cardText'>{t('translation:featuresCardText9')}</p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Features
