import { SiFacebook } from 'react-icons/si'
import { IoLogoInstagram } from 'react-icons/io'
import { FaLinkedinIn, FaGithub, FaReddit, FaYoutube } from 'react-icons/fa'

const SocialIcons = () => {
  return (
    <div className='socialIcons'>
      <a
        href='https://www.facebook.com/Promodexio-103263551995647%20'
        target='_blank'
        className='socialLink'
        rel='noreferrer'
      >
        <SiFacebook />
      </a>

      <a
        href='https://www.instagram.com/promodexio/'
        target='_blank'
        className='socialLink'
        rel='noreferrer'
      >
        <IoLogoInstagram />
      </a>

      <a
        href='https://www.linkedin.com/company/promodexio/'
        target='_blank'
        className='socialLink'
        rel='noreferrer'
      >
        <FaLinkedinIn />{' '}
      </a>

      <a
        href='https://github.com/promodexio/promodex.io'
        target='_blank'
        className='socialLink'
        rel='noreferrer'
      >
        <FaGithub />
      </a>

      <a
        href='https://www.reddit.com/user/promodexio%20'
        target='_blank'
        className='socialLink'
        rel='noreferrer'
      >
        <FaReddit />
      </a>

      <a
        href='https://www.youtube.com/channel/UCEtCnSnFlHqgB6woV4HVP4w'
        target='_blank'
        className='socialLink'
        rel='noreferrer'
      >
        <FaYoutube />
      </a>
    </div>
  )
}

export default SocialIcons
