const FrameVideo = () => {
  return (
    <iframe
      width='1280'
      height='720'
      src='https://www.youtube.com/embed/ZnMfRoYBG6Q?autoplay=1'
      title='YouTube video player'
      frameborder='0'
      allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
      allowfullscreen
    ></iframe>
  )
}

export default FrameVideo
