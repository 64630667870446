import { IoChevronBackCircleSharp } from 'react-icons/io5'
import { useNavigate } from 'react-router-dom'

const PrivacyPolicy = () => {
  const navigate = useNavigate()
  return (
    <div className='policies'>
      <div className='backHome'>
        <IoChevronBackCircleSharp onClick={() => navigate('/')} />
      </div>
      <h1>Privacy Policy</h1>
      <p>
        We are Promodex; a provider of Website, server and blockchain technology
        services and we are committed to protecting your privacy. In this
        Privacy Policy, personal information means any personal information
        about you which could identify you (by itself or in combination with
        other data) such as your name, address, email address, wallet address or
        an online identifier, such as a unique ID, or your Internet Protocol
        (IP) address.
      </p>
      <p>
        This Privacy Policy (the “Policy”) describes the types of information
        Promodex (the “Company”, “us”, “we”, or “our”) may collect from you or
        that you may provide when you visit the Website and the products,
        features, materials, and services we offer (collectively with the
        Website, the “Services”). This Policy also describes our policies and
        procedures for collecting, using, maintaining, protecting, and
        disclosing that information.
      </p>
      <p>
        This Policy applies to information we collect on the Website and through
        your use of the Services generally (including when you register for an
        account), and through communications between you and the Website
        (including email, text, and other electronic messages).
      </p>
      <p>
        This Policy does not apply to information collected by third parties,
        including any websites, services, and applications that you elect to
        access through the Services. [It also does not apply to any information
        collected by the Company offline or through any other means, including
        any other website operated by us or any third party (including our
        affiliates and subsidiaries).]
      </p>
      <p>
        Please review this Policy carefully. By accessing or using the Services
        (or by clicking on “accept” or “agree” to this Policy when prompted),
        you agree to the terms of this Policy on behalf of yourself or the
        entity or organization that you represent. If you do not agree to any
        term in this Policy, you should refrain from further use of our
        Services. 1. CHILDREN PRIVACY The Services and its content are not
        intended for, nor directed at, children under the age of thirteen (13).
        No one under the age of thirteen (13) may provide any personal
        information to or on the Services. Please note that we do not knowingly
        collect any personally identifiable information from children under the
        age of thirteen (13). If you are under the age of thirteen (13), please
        do not attempt to use or register for the Services or send any
        information about yourself to us, including your name, address,
        telephone number, or email address. If it is determined that we have
        inadvertently collected or received personally identifiable information
        from a child under the age of thirteen (13), we shall immediately take
        the necessary steps to ensure that such information is deleted from our
        system’s database. If you are a parent or legal guardian and think your
        child under the age of thirteen (13) has given us information, you can
        contact us at support [at] promodex . io (Neglect the spaces) Please be
        sure to include your email address and a telephone number where we can
        reach you. Additionally we recommend parents and guardians of children
        using our Services regularly clear their browser cache and/or browsing
        history to clear cookies. Please see our Cookies Policy for more
        information.
      </p>

      <h3>2. INFORMATION WE COLLECT </h3>
      <p>
        We receive several types of information about you from various sources,
        including (A) information and content that you give us; (B)
        automatically collected information; [and] (C) demographic information
        or other aggregate information; and (D) information from other sources.
        Each is described in further details below.
      </p>
      <h4>A. Information and Content You Give Us.</h4>
      <p>
        We collect personal information that you knowingly choose to disclose.
        This may include:
      </p>
      <p>
        (i) Personal Information. Personal information, such as your name,
        address, e-mail address, username, password, and any other information
        you directly provide us on or through the Services. This includes
        information you provide when you register or create an account, or
        request for customer service.
      </p>
      <p>
        (ii) Email Correspondences. Records and copies of your email messages
        together with your email address, name, phone number and our responses,
        if you choose to correspond with us through email, raise a complaint,
        ask for technical support or report a problem with any of our Services.
      </p>
      <p>
        (iii) User Content. Information or content you submit to be published or
        displayed on public areas of the Services or transmitted to other users
        of the Services or third parties (collectively, “User Content”). Your
        User Content is posted on and transmitted to others at your own risk.
        The Company cannot control the actions of other users of the Services
        with whom you may choose to share your User Content. Therefore, we
        cannot and do not guarantee that your User Content will not be viewed by
        unauthorized persons.
      </p>
      <p>
        (iv)Transaction Information. Information about any purchase or
        transactions made on the Services. This includes payment information,
        such as your credit or debit card number and other card information;
        other account and authentication information; and billing, shipping, and
        contact details.
      </p>
      <h4>B. Information We Collect Automatically.</h4>
      <p>
        We may use a variety of technologies to collect certain information
        about your equipment, browsing actions, and patterns whenever you
        interact with the Services, including:
      </p>
      <p>
        (i) Activity Information. Details of your visits to our Services,
        including the types of content you view or engage with; the features you
        use; the actions you take; the people or accounts you interact with; the
        time, frequency, and duration of your activities; and other information
        about your use of and actions on the Services.
      </p>
      <p>
        (ii) Equipment Information. Information about your computer and internet
        connection, including your computer operating system, IP address,
        browser type, and browser language.
      </p>
      <p>
        (iii) Location Information. Information about the location of your
        device, including GPS location, for purposes of enhancing or
        facilitating the Services. For example, such information may be used to
        enable the functionality or features of the Services that provide you
        with information about stores near you, or enable you to remotely order
        and pay for the Services. We may also use information about the location
        of the device you are using to help us understand how the Services and
        functionality are being used and to deliver more relevant advertising.
      </p>
      <p>
        The gathering of this information may be used for maintaining the
        quality of the Services we provide, as well as providing overall general
        statistics related to the use of the Services.{' '}
      </p>
      <p>
        The technologies we use for this automatic data collection may include:
      </p>
      <p>
        (i) Cookies. A cookie is a small data file stored on the hard drive of
        your computer either for only the duration of your visit on a website
        (“session cookies”) or for a fixed period (“persistent cookies”).
        Cookies contain information that can later be read by a web server. We
        may use cookies to provide you with a more personal and interactive
        experience on the Services.
      </p>
      <p>
        (ii) Web Beacons. Web beacons are small files that are embedded in
        webpages, applications, and emails (also known as “clear gifs”, “pixel
        tags”, “web bugs”, and “single-pixel gifs”). Web beacons allow the
        Company, for example, to track who has visited those webpages or opened
        an email, to test the effectiveness of our marketing, and for other
        related website statistics.
      </p>
      <p>
        (iii) JavaScripts. JavaScripts are code snippets embedded in various
        parts of websites and applications that facilitate a variety of
        operations including accelerating the refresh speed of certain
        functionality or monitoring usage of various online components.
      </p>
      <p>
        (iv) Entity Tags. Entity Tags are HTTP code mechanisms that allow
        portions of websites to be stored or “cached” within your browser and
        validates these caches when the website is opened, accelerating website
        performance since the web server does not need to send a full response
        if the content has not changed.
      </p>
      <p>
        (v) HTML5 Local Storage. HTML5 local storage allows data from websites
        to be stored or “cached” within your browser to store and retrieve data
        in HTML5 pages when the website is revisited.
      </p>
      <p>
        (vi) Resettable Device Identifiers. Resettable device identifiers (also
        known as “advertising identifiers”) are similar to cookies and are found
        on many mobile devices and tablets (for example, the “Identifier for
        Advertisers” or “IDFA” on Apple iOS devices and the “Google Advertising
        ID” on Android devices), and certain streaming media devices. Like
        cookies, resettable device identifiers are used to make online
        advertising more relevant.
      </p>
      <p>
        This Policy does not cover the use of tracking technologies by third
        parties. The Services may contain links, content, advertising, or
        references to other websites by third parties, including advertisers, ad
        networks and servers, content providers, and application providers.
        These third parties may use cookies or other tracking technologies to
        collect information about you when you use the Services. The information
        they collect may be associated with your personal information or they
        may collect information about your online activities over time and
        across different websites. Please be aware that we do not control these
        third parties’ tracking technologies or when and how they may be used.
        Therefore, the Company does not claim nor accept responsibility for any
        privacy policies, practices, or procedures of any third party. If you
        have any questions about an ad or other targeted content, you should
        contact the responsible provider directly.
      </p>
      <h4>C. Demographic Information.</h4>
      <p>
        We may collect demographic, statistical, or other aggregate information
        that is about you, but individually does not identify you. Some of this
        information may be derived from personal information, but it is not
        personal information and cannot be tied back to you. Examples of such
        aggregate information include gender, age, race, household income, and
        political affiliation.
      </p>
      <h5>D. Information from Other Sources.</h5>
      <p>
        We may receive information about you from other sources and add it to
        our account information. We protect this information according to the
        practices described in this Policy, plus any additional restrictions
        imposed by the source of the data. These sources may include online and
        offline data providers, from which we obtain demographic,
        interest-based, and online advertising related data; publicly-available
        sources such as open government databases or social networks; and
        service providers who provide us with information, or updates to that
        information, based on their relationship with you. By gathering
        additional information about you, we can correct inaccurate information,
        enhance the security of your transactions, and give you product or
        service recommendations and special offers that are more likely to
        interest you.
      </p>
      <h3>3. HOW WE USE YOUR INFORMATION</h3>
      <p>
        We may use the information we collect about you in a variety of ways,
        including to:
      </p>
      <p>(i) provide the Services and its content to you;</p>
      <p>
        (ii) respond to comments and questions, and provide customer service;
      </p>
      <p>
        (iii) fulfill any other purpose for which you provide such information;
      </p>
      <p>
        (iv) communicate with you about your order, purchase, account, or
        subscription;
      </p>
      <p>
        (v) inform you about important changes to, or other news about, the
        Services or any of its features or content;
      </p>
      <p>
        (vi) operate, maintain, improve, personalize, and analyze the Services;
      </p>
      <p>
        (vii) monitor and analyze trends, usage, and activities for marketing or
        advertising purposes;
      </p>
      <p>
        (viii) detect, prevent, or investigate security breaches, fraud,other
        unauthorized or illegal activity and restrict customers from attempting
        to use our Services from a restricted territory or who are under-age and
        attempting to access inappropriate content.
      </p>
      <p>
        (ix) carry out our obligations and enforce our rights arising from any
        contracts entered into between you and us, including for billing and
        collection;
      </p>
      <p>
        (x) maintain appropriate records for internal administrative purposes;
      </p>
      <p>
        (xi) allow you to participate in interactive features on the Services;
      </p>
      <p>
        (xii) send promotional communications, such as information about
        features, newsletters, offers, promotions, contests, and events;
      </p>
      <p>
        (xiii) share information across the Company’s products and devices to
        provide a more tailored and consistent experience on all Company
        products you use;
      </p>
      <p>
        (xiv) develop, test and improve new products or services, including by
        conducting surveys and research, and testing and troubleshooting new
        products and features;
      </p>
      <h3>4. WHEN WE SHARE YOUR INFORMATION</h3>
      <p>
        We may disclose aggregated or anonymized information about our users
        without any restrictions. We will not share your personal information
        that we collect or you provide as described in this Policy except in the
        following circumstances:
      </p>
      <p>
        (A) With subsidiaries and affiliates for business purposes. To our
        subsidiaries and affiliated companies for purposes of management and
        analysis, decision-making, and other business purposes.
      </p>
      <p>
        (B) When we work with service providers. To service providers,
        contractors, and other third parties that provide us with support
        services, such as credit card processing, website hosting, email and
        postal delivery, location mapping, product and service delivery, or
        analytics services, and who are bound by contractual obligations to keep
        personal information confidential and use it only for the purposes for
        which we disclose it to them.
      </p>
      <p>
        (C) When we become involved in a sale or transfer of the Company. If we
        become involved with a merger, divestiture, restructuring,
        reorganization, dissolution, or other sale or transfer of some or all of
        the Company’s assets (whether as a going concern or as part of
        bankruptcy, liquidation, or similar proceeding), to business entities or
        people involved in the negotiation or transfer.
      </p>
      <p>
        (D) When we are required by law. To comply with any court order, law, or
        legal process, including to respond to any government or regulatory
        request.
      </p>
      <p>
        (E) When we enforce our rights. To enforce or apply this Policy, Cookie
        Policy, and other agreements, including for billing and collection
        purposes.
      </p>
      <p>
        (F) To help protect lawful interests. If we believe disclosure will help
        us protect the rights, property, or safety of the Company, our users,
        partners, agents, and others. This includes exchanging information with
        other companies and organizations for fraud protection, and spam and
        malware prevention.
      </p>
      <p>
        (G) To fulfill the purpose for that information or with your consent. To
        fulfill the purpose for which you provide the information, for any other
        purpose disclosed by us when you provide the information, or with your
        consent.
      </p>
      <p>
        (H) When we work with marketing service providers. To marketing service
        providers to assess, develop, and provide you with promotions and
        special offers that may interest you, administer contests, sweepstakes,
        and events, or for other promotional purposes.
      </p>
      <p>
        Information that you post on or through the public areas of the Services
        (e.g., chat rooms, bulletin boards, and discussion groups) are generally
        accessible to, and may be collected and used by, others and may result
        in unsolicited messages or other contact from others. Users of the
        Services are encouraged to exercise caution when providing personal
        information about themselves in public or interactive areas.
      </p>
      <h3>5. WHERE WE MAY TRANSFER YOUR INFORMATION</h3>
      <h5>5.1 Storage Location</h5>
      <p>
        We engage the services of a cloud storage provider in providing our
        Services. This means that the personal information we collect from you
        might be used, stored and/or accessed by our staff, members of our
        group, or suppliers outside of country; such as in the European Economic
        Area (“EEA”), the USA, Canada, Asia Pacific, and other locations where
        our provider has servers. Further details on to whom your personal
        information may be disclosed are set out in section 2 and 3.
      </p>
      <p>
        5.2 Privacy Pertaining to Your Personal Information Stored Outside of
        The EEA Where we transfer any personal information about you to any
        location outside the EEA, we will take appropriate measures to ensure
        that the recipient protects your personal information adequately in
        accordance with this Privacy Policy. These measures may include the
        following permitted in Articles 45 and 46 of the EU’s General Data
        Protection Regulation:
      </p>
      <p>
        in the case of USA based entities, entering into European Commission
        approved standard contractual arrangements with them;
      </p>
      <p>
        in the case of Canada based entities, relying on the European
        Commission’s determination that Canadian law provides adequate
        protection for such personal information; and
      </p>
      <p>
        in the case of entities based in other countries outside the EEA,
        entering into European Commission approved standard contractual
        arrangements with them.
      </p>
      <h3>6. MECHANISMS</h3>
      <p>
        We keep your personal information for no longer than necessary for the
        purposes for which the personal information is processed. The length of
        time for which we retain personal information depends on the purposes
        for which we collect and use it and/or as required to comply with
        applicable laws and to establish, exercise or defend our legal rights.
        In case you wish to remove your personal information from the Services
        (except your IP address), you can close your account at any time.
      </p>
      <h4>6.1 Mechanisms to Control Your Information</h4>
      <p>
        We strive to provide you with choices regarding the personal information
        you provide to us. We have created mechanisms to provide you with the
        following control over your information:
      </p>
      <p>
        (i) Cookies & Other Tracking Technologies. You may be able to set your
        browser to reject cookies and certain other technologies by adjusting
        the appropriate settings in your browser. Each browser is different, but
        many common browsers have preferences that may be adjusted to allow you
        to either accept or reject cookies and certain other technologies before
        they are set or installed, or allow you to remove or reject the use or
        installation of certain technologies altogether. We recommend that you
        refer to the Help menu in your browser to learn how to modify your
        browser settings. Please note that you cannot remove Flash cookies
        simply by changing your browser settings. To learn how you can manage
        your Flash cookie settings, visit the Flash player settings page on
        Adobe’s website. If you disable or refuse cookies, please note that some
        parts of the Services may become inaccessible or may not function
        properly. For further information please check Cookies Policy (
        https://promodex.io/cookies-policy )
      </p>
      <p>
        (ii) Promotional Communications from the Company. If you do not wish to
        have your contact information used by the Company to promote our own or
        third-party products or services, you can opt-out by (i) informing us of
        your preference at the time you sign up for our newsletter, or complete
        any other form on or through the Services which we collect your data;
        (ii) modifying your user preferences in your account profile by checking
        or unchecking the relevant boxes; (iii) following the opt-out
        instructions in the promotional emails we send you; or (iv) sending us
        an email stating your request. Please note that if you opt out of
        receiving promotional communications from us, we may still send you
        transactional communications, including emails about your account or
        purchases.
      </p>
      <h4>6.2. Mechanisms to Access and Correct Your Information</h4>
      <p>
        You may send us an email to request access to, correct, or remove any
        personal information that you have provided to us. You may also access,
        correct, or remove your personal information by logging into the Website
        and visiting your account profile page. [We cannot delete your personal
        information except by also deleting your account.]
      </p>
      <p>
        We may not accommodate a request to change information if we believe the
        change would violate any law or legal requirement or cause the
        information to be incorrect.
      </p>
      <p>
        [If you delete your User Content from the Services or your account,
        copies of your User Content may remain viewable in cached and archived
        pages, or might have been copied or stored by other users of the
        Services.]
      </p>
      <h3>7. HOW WE PROTECT YOUR INFORMATION</h3>
      <p>
        We take reasonable precautions to secure your personal information. [We
        have implemented technical, physical, and administrative security
        measures designed to reduce the risk of loss, misuse, unauthorized
        access, disclosure, or modification of your information. We have put in
        place appropriate physical, electronic, and managerial procedures to
        safeguard the information we collect. All information you provide to us
        is stored on our secure servers behind firewalls. When you transmit
        highly sensitive information (such as a credit card number) through the
        Services, we encrypt the transmission of that information using Secure
        Sockets Layer (SSL) technology.]
      </p>
      <p>
        The safety and security of your information also depend on you. Where we
        have given you (or where you have chosen) a password for access to
        certain parts of the Services, you are responsible for keeping this
        password confidential. We ask you not to share your password with
        anyone.
      </p>
      <p>
        While we have employed security technologies and procedures to assist
        safeguarding your personal information, no system or network can be
        guaranteed to be 100% secure. Unauthorized entry or use, hardware or
        software failure, and other factors may compromise the security of user
        information at any time. Any transmission of personal information is at
        your own risk. We are not responsible for circumvention of any privacy
        settings or security measures contained on the Website or any other
        Services.
      </p>
      <h3>8. CHANGES OF PRIVACY POLICY</h3>
      <p>
        This Policy was last revised on the date noted at the top of this page.
        We may update this Policy from time to time. If we make material
        changes, we will post the updated Policy on this page and notify you of
        such changes by means of [an email to the email address specified in
        your account, a message on the Services, or through a notice on the
        Website home page]. Your continued use of the Services after we make
        changes is deemed to be acceptance of those changes, so please check the
        Policy periodically for updates.
      </p>
      <h3>9. FURTHER QUESTIONS</h3>
      <p>
        If you choose to visit the Services, your visit and any dispute over
        privacy is subject to this Policy and Cookie Policy
      </p>
      <p>
        If you have any queries or complaints about our collection, use or
        storage of your personal information, or if you wish to exercise any of
        your rights in relation to your personal information, please contact our
        privacy team at support [at] promodex . io (Neglect the spaces) We will
        investigate and attempt to resolve any such complaint or dispute
        regarding the use or disclosure of your personal information.
      </p>
      <p>
        The practices described in this Privacy Policy are current as of October
        1, 2021.
      </p>
      <p>Promodex.io</p>
      <p>
        Programmatic Promotion Marketplace Create, target, budget your campaign
        Get your message spread by a number of influencers and followers all
        over the world.
      </p>
    </div>
  )
}

export default PrivacyPolicy
