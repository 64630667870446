import { IoChevronBackCircleSharp } from 'react-icons/io5'
import { useNavigate } from 'react-router-dom'

const CookiesPolicy = () => {
  const navigate = useNavigate()
  return (
    <div className='policies'>
      <div className='backHome'>
        <IoChevronBackCircleSharp onClick={() => navigate('/')} />
      </div>
      <h1>Cookies Policy</h1>
      <p>
        ‍When you use Promodex Services Website; we use cookies, pixels, and
        similar technologies to gather data about your use of our Website.
      </p>
      <p>
        Cookies are small files that we may send to your browser when you first
        use the Website, which are then stored on your device in order to
        remember information about you (such as login details). Those cookies
        are set by us and called first-party cookies. We also use third-party
        cookies – which are cookies from a domain different than the domain of
        the Website you are visiting – for our advertising and marketing
        efforts.
      </p>
      <p>
        Cookies of the Website are used with few different ways under the
        following categories:
      </p>
      <p>
        ‍Necessary Cookies – These are cookies that are required for the
        operation of our Website or to comply with legal requirements. These
        cannot be switched off in our systems. They are usually only set in
        response to actions made by you which amount to a request for services.
      </p>
      <p>
        They include, for example, cookies that enable you to log into secure
        areas of our Website or help to ensure the content of pages loads
        quickly. You can set your browser to block or alert you about these
        cookies, but some parts of our Website will not then work.
      </p>
      <p>
        ‍Functionality Cookies – These are required for our Website to function
        correctly and to give you access to the Website that we offer. For
        example, these cookies allow the Website to remember any settings you
        make and provide enhanced and more personal features. If you do not
        allow these cookies then some or all of Website Services may not
        function properly.
      </p>
      <p>
        ‍Analytical or Performance Cookies – These allow us to recognise and
        count the number of visitors and to see how visitors move around our
        Website when they are using them. This helps us to improve the way our
        Website work, for example, by ensuring that users are finding what they
        are looking for easily. If you do not allow these cookies, we will not
        know when you have visited our Website
      </p>
      <p>
        ‍Targeting Cookies – These cookies record your visit to our Website, the
        pages you have visited and the links you have followed. We will use this
        information to make our Website more relevant to your interests. These
        cookies may also be set through our Website by our advertising partners.
        They may be used by those companies to build a profile of your interests
        and show you relevant adverts on other sites. They do not store directly
        personal information but are based on uniquely identifying your browser
        and internet device. If you do not allow these cookies, you will
        experience less targeted advertising.
      </p>
      Cookies collect data about you but are typically constructed with privacy
      by design. Only the server that sets the cookie can retrieve the data it
      collects, and the data is often collected on an anonymized basis.
      <p>
        Some content or applications on the Website are served by third-parties.
        These third parties may use cookies alone or in conjunction with web
        beacons or other tracking technologies to collect information about you
        when you use our Website. The information collected by these cookies is
        directly obtained by such third parties, in some cases are not disclosed
        to us, are used and managed in accordance with the privacy and cookies
        policies of those third parties and are not under our control. We do not
        control these third parties’ tracking technologies or how they may be
        used. For more information about such cookies, you should contact the
        responsible provider directly.
      </p>
      <h3>‍HOW CAN YOU MANAGE COOKIES USED ON THE WEBSITE?</h3>
      <p>
        ‍When you first visit our Website, you are presented with a cookie
        banner that allows you to either opt in or opt out from our use of
        cookies. If you consent to our use of cookies, such cookies will be sent
        to and stored on your device.
      </p>
      <p>
        If you opt out and do not consent, we have a special script in place
        which ensures that no cookies that collect your data are being used. You
        can also choose not to allow some types of cookies. However, blocking
        some types of cookies may impact your experience of the Website and the
        Services we are able to offer.
      </p>
      <p>
        All browser technologies enable you to manage the cookies in the cookie
        folder of your web browser. This means that you can either delete
        cookies from your cookie folder once you have finished your visit to our
        Website or you can set your preferences with regard to the use of
        cookies before you begin browsing our Website. Please note, as stated
        above, that deleting or rejecting cookies may adversely affect your user
        experience of our Sites.
      </p>
      <p>You can find out further information at:</p>
      <p>-Google</p>
      <p>-Firefox</p>
      <p>-Windows</p>
      <p>-Safari</p>
      <h3>CHANGES TO THIS COOKIES POLICY</h3>
      <p>
        ‍From time to time, we may make changes to this Cookies Policy. If we
        decide to do so, we will post the changes on our Website, so that you
        are always aware of what cookies we use and how we use them.
      </p>
      <h3>CONTACT DETAILS</h3>
      <p>
        ‍If you have any questions, comments or requests relating to our use of
        cookies, or if you would like to exercise any of your rights under this
        policy, then please contact us at support [at] promodex . io (Please
        neglect the spaces)
      </p>
      <p>Promodex.io</p>
      <p>
        Programmatic Promotion Marketplace Create, target, budget your campaign
        Get your message spread by a number of influencers and followers all
        over the world.
      </p>
    </div>
  )
}

export default CookiesPolicy
