import { useTranslation } from 'react-i18next'
import ixir from '../../../assets/platforms/ixir.svg'
import kdg from '../../../assets/platforms/kdg.svg'
import kommunitas from '../../../assets/platforms/kommunitas.svg'
import poolz from '../../../assets/platforms/poolz.svg'
import spres from '../../../assets/platforms/spres.svg'
import trustFi from '../../../assets/platforms/trustfi.svg'
import whitebitTr from '../../../assets/platforms/whitebit-flag.svg'
import whitebit from '../../../assets/platforms/whitebit.svg'
import Line from '../../general/Line'
import FundraiseCard from './FundraiseCard'
import FundraiseWhiteCard from './FundraiseWhiteCard'
const UpcomingFundraise = () => {
  const { t } = useTranslation()


  const publicRounds = [
    {
      name: 'trustFi',
      image: trustFi,
      totalAllocation: '50.000 USD',
      ido: 'Launchpad IDO',
      date: '27.06.2024',
      buyNowLink: 'https://launchpad.trustfi.org/#/v3/Promodex/join?poolId=3008',
      detailLink: 'https://medium.com/@promodex/promodex-token-sale-on-trustfi-8871b3c52eca',
      isSoldOut: true
    },
    {
      name: 'komunitas',
      image: kommunitas,
      totalAllocation: '200.000 USD',
      ido: 'Launchpad IDO',
      date: '28.06.2024',
      buyNowLink: 'https://launchpad.kommunitas.net/pool/PROMO/PublicCross',
      detailLink: 'https://promodex.medium.com/promodex-token-sale-on-kommunitas-9261da029efb',
      isSoldOut: true
    },
    {
      name: 'ixir',
      image: ixir,
      totalAllocation: '200.000 USD',
      ido: 'Launchpad IDO',
      date: '28.06.2024',
      buyNowLink: 'https://www.ixirpad.com/launchpad/7yjg78ox6dyxcvn',
      detailLink: 'https://promodex.medium.com/promodex-token-sale-on-ixirpad-9111e80e4dff',
      isSoldOut: true
    },
    {
      name: 'spres',
      image: spres,
      totalAllocation: '150.000 USD',
      ido: 'Launchpad IDO',
      date: '29.06.2024',
      buyNowLink: 'https://launchpad.spores.app/ino/promodex-ido',
      detailLink: 'https://promodex.medium.com/promodex-token-sale-on-spores-network-b9e4fbdf8f5e',
      isSoldOut: true
    },
    {
      name: 'kdg',
      image: kdg,
      totalAllocation: '100.000 USD',
      ido: 'Launchpad IDO',
      date: '29.06.2024',
      buyNowLink: 'https://bit.ly/KingdomStarter-Promodex-Sale',
      detailLink: 'https://promodex.medium.com/promodex-token-sale-on-kingdom-starter-fb3f3ee548c4',
      isSoldOut: true
    },
    {
      name: 'poolz',
      image: poolz,
      totalAllocation: '50.000 USD',
      ido: 'Launchpad IDO',
      date: '01.07.2024',
      buyNowLink: 'https://bit.ly/Promodex-Poolz-Sale',
      detailLink: 'https://promodex.medium.com/promodex-token-sale-on-poolz-finance-f4ef0c7418ed',
      isSoldOut: true
    },
    {
      name: 'whitebit',
      image: whitebitTr,
      totalAllocation: '150.000 USD',
      ido: 'Exchange IEO',
      date: '01.07.2024',
      buyNowLink: 'https://bit.ly/Promodex-Whitebit-Launchpad',
      detailLink: 'https://bit.ly/Promodex-Whitebit-Sale-Listing'
    },
    {
      name: 'whitebit2',
      image: whitebit,
      label: 'Deposit Time',
      totalAllocation: '02.07.2024 14:00 (UTC)',
      ido: 'Deposit & Listing',
      dateLabel: 'Listing (Trade Starts)',
      date: '03.07.2024 10.00 (UTC)',
      buyNowLink: 'https://bit.ly/Trade-Promodex',
      detailLink: 'https://bit.ly/Promodex-Whitebit-Sale-Listing',
      price: '0.030',
      buttonName: 'Trade Now',
    },
  ]

  return (
    <div id='fundraise' className='fundraise'>
      <div className='fundraiseHead'>
        <h4>{t('translation:upcomming')}</h4>
        <Line />
      </div>
      <div className='card-title' style={{
        display: 'flex',
        justifyContent: 'center',
        fontSize: 22,
        fontWeight: 600,
      }}>Pre-Public Rounds</div>
      <div className='fundraiseCards'>
        <FundraiseCard
          title="Seed Fund"
          detailLink="https://promodex.medium.com/promodex-starts-the-seed-funding-round-with-a-special-price-for-a-limited-time-period-80d60f1b338"
          price="0,012"
          total="108.000"
          date="Ended"
          isSoldOut
        />
        <FundraiseCard
          title="Presale"
          detailLink="https://bit.ly/Promodex-Tokenomics"
          buyNowLink="https://bit.ly/Presale-Form"
          price="0,015"
          total="270.000"
          date="03.07.2024"
          isOfferEndSoon
        />
        <FundraiseCard
          title="Strategic Round"
          detailLink="https://bit.ly/Promodex-Tokenomics"
          buyNowLink="https://bit.ly/Presale-Form"
          price="0,015"
          total="810.000"
          date="03.07.2024"
          isOfferEndSoon
        />
        <FundraiseCard
          title="Private Sale"
          price="0,020"
          total="180.000"
          date="03.07.2024"
          detailLink="https://bit.ly/Promodex-Tokenomics"
          buyNowLink="https://bit.ly/Presale-Form"
          isOfferEndSoon
        />
      </div>
      <div className='card-title' style={{
        display: 'flex',
        justifyContent: 'center',
        fontSize: 22,
        fontWeight: 600,
        marginTop: 40
      }}>Public Rounds</div>
      <div className='fundraiseCards'>
        {publicRounds.slice(0, 4).map((dt,index) => (
          <FundraiseWhiteCard
            key={index}
            name={dt.name}
            header={dt.image}
            title={dt.ido}
            totalAllocation={dt.totalAllocation}
            detailLink={dt.detailLink}
            buyNowLink={dt.buyNowLink}
            date={dt.date}
            price={dt.price ? dt.price : "0,025"}
            label={dt.label}
            time={dt.time}
            dateLabel={dt.dateLabel}
            buttonName={dt.buttonName}
            isSoldOutLabel={dt.isSoldOut}
          />
        ))
        }
      </div>
      <div className='fundraiseCards' style={{ marginTop: 40 }}>
        {publicRounds.slice(4, 8).map((dt, index) => (
          <FundraiseWhiteCard
            key={index}
            name={dt.name}
            header={dt.image}
            title={dt.ido}
            totalAllocation={dt.totalAllocation}
            detailLink={dt.detailLink}
            buyNowLink={dt.buyNowLink}
            date={dt.date}
            price={dt.price ? dt.price : "0,025"}
            label={dt.label}
            time={dt.time}
            dateLabel={dt.dateLabel}
            buttonName={dt.buttonName}
            isSoldOutLabel={dt.isSoldOut}
          />
        ))
        }
      </div>
    </div>
  )
}

export default UpcomingFundraise
