import React from 'react'

const InfluencerProfile = ({ img, link }) => {
  return (
    <div className='image-wrapper'>
      <a href={link} target='_blank' rel='noreferrer'>
        <img src={img} alt='infuluncer-profile' />
      </a>
    </div>
  )
}

export default InfluencerProfile
