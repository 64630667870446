// import { useTranslation } from 'react-i18next'
import Line from '../../general/Line'

// import Slider from 'react-slick'

//Partners Logo
// import CoinAjans from '../../../assets/partners/CoinAjans_250x125_decuped.png'
// import KriptoPara from '../../../assets/partners/Kriptoparahaber_250x125_whitebg-1.png'
// import alphaCapital from '../../../assets/partners/AlphaCapital.jpeg'


import lydia from '../../../assets/partners/LydiaCapital.png'
import alphaCapital from '../../../assets/partners/AlphaCapitalBlack.png'
import decubate from '../../../assets/partners/decubate.png'
import whitebit from '../../../assets/partners/whitebit.png'
import hyperAuditor from '../../../assets/partners/hypeauditor.svg'
import Devtagon from '../../../assets/partners/Devtagon_250x125_decuped.png'
import antier from '../../../assets/partners/antier.svg'
import validentry from '../../../assets/partners/validentry.svg'
import koinmedya from '../../../assets/partners/koinmedya.svg'
import chainsocial from '../../../assets/partners/chainsocial.svg'
import becommer from '../../../assets/partners/becommer.svg'
import parrot from '../../../assets/partners/parrot.svg'
import gptVerse from '../../../assets/partners/gpt-verse.svg'
import marsala from '../../../assets/partners/marsala.svg'
import bnbChain from '../../../assets/partners/bnb-chain.svg'
import gempad from '../../../assets/partners/gempad.svg'
import iluminaryai from '../../../assets/partners/iluminaryai.svg'
import friend3 from '../../../assets/partners/friend3.svg'
import taskon from '../../../assets/partners/taskon.svg'
import poolz from '../../../assets/partners/poolz.svg'
import kommunitas from '../../../assets/partners/kommunitas.svg'
import cls from '../../../assets/partners/cls.svg'
import spores from '../../../assets/partners/spores.svg'
import kdg from '../../../assets/partners/kdg.svg'
import trustfi from '../../../assets/partners/trustfi.svg'
import ixir from '../../../assets/partners/ixir.svg'
import galxe from '../../../assets/partners/galxe.svg'


// import { useWindowWidth } from '../../utils/useWindowWidth'
import PartnerCard from './PartnerCard'

const Partners = () => {
  // const innerWidth = useWindowWidth()
  // const slides = () => {
  //   if (innerWidth >= 1200) {
  //     return 4
  //   }
  //   if (innerWidth >= 812) {
  //     return 3
  //   } else {
  //     return 2
  //   }
  // }

  // const { t } = useTranslation()
  // const settings = {
  //   dots: false,
  //   infinite: true,
  //   autoplay: true,
  //   speed: 500,
  //   slidesToShow: slides(),
  //   slidesToScroll: 1,
  //   arrows: false,
  // }

  const partners = [
    {
      img: lydia,
      name: 'Lydia Capital',
    },
    {
      img: alphaCapital,
      name: 'Alpha Capital',
    },
    {
      img: decubate,
      name: 'Decubate',
    },
    {
      img: whitebit,
      name: 'Whitebit',
    },
    {
      img: hyperAuditor,
      name: 'Hyper Auditor',
    },
    {
      img: Devtagon,
      name: 'Devtagon',
    },
    {
      img: antier,
      name: 'Antier',
    },
    {
      img: validentry,
      name: 'Valid Entry',
    },
    {
      img: koinmedya,
      name: 'Koinmedya',
    },
    {
      img: chainsocial,
      name: 'Chain Social',
    },
    {
      img: becommer,
      name: 'Becommer',
    },
    {
      img: parrot,
      name: 'Parrot',
      width: 75
    },
    {
      img: gptVerse,
      name: 'GPT Verse',
    },
    {
      img: marsala,
      name: 'Marsala',
    },
    {
      img: bnbChain,
      name: 'BNB Chain',
    },
    {
      img: gempad,
      name: 'Gempad',
    },
    {
      img: iluminaryai,
      name: 'Iluminary AI',
    },
    {
      img: friend3,
      name: 'Friend3',
    },
    {
      img: taskon,
      name: 'Taskon',
    },
    {
      img: poolz,
      name: 'Poolz',
    },
    {
      img: kommunitas,
      name: 'Kommunitas',
    },
    {
      img: cls,
      name: 'CLS',
    },
    {
      img: spores,
      name: 'Spores',
    },
    {
      img: kdg,
      name: 'KDG',
    },
    {
      img: trustfi,
      name: 'TrustFi',
    },
    {
      img: ixir,
      name: 'Ixir',
    },
    {
      img: galxe,
      name: 'Galxe',
    },
    {}
  ]

  return (
    <section id="partners" className='partners'>
      <h3>Investors & Partners</h3>
      <Line />

      <div className='partners-container'>
        {partners.map((p, index) => (
          <PartnerCard key={index} name={p.name} src={p.img} width={p.width} />
        ))}
      </div>

      <div className='container'>
        {/* <Slider {...settings}>
          <PartenersLogo img={Devtagon} link='https://devtagon.com' />
          <PartenersLogo img={CoinAjans} link='https://coinajans.com/' />
          <PartenersLogo img={KoinMedya} link='https://koinmedya.com/' />
          <PartenersLogo img={KriptoPara} link='https://kriptoparahaber.com/' />
          <PartenersLogo
            img={alphaCapital}
            link='https://www.alphacapital.financial/'
          />
        </Slider> */}
      </div>
    </section>
  )
}

export default Partners
