import { BsLinkedin } from 'react-icons/bs'
const Profile = ({ name, titr, linkdn, photoURI }) => {
  return (
    <div className='profile'>
      <div className='profileImg'>
        <img src={photoURI} alt={name} width={102} height={102} style={{
          width: 102,
          height: 102,
          aspectRatio: '1/1',
          objectFit: 'cover',
        }} />
        <a href={linkdn} target='_blank' rel="noreferrer">
          <BsLinkedin />
        </a>
      </div>
      <div className='profileName'>
        <p>{name}</p>
        <p className='titr'>{titr}</p>
      </div>
    </div>
  )
}

export default Profile
