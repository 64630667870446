import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IoMdPlay } from 'react-icons/io'
import {
  AiFillFileWord,
  AiOutlineFilePdf,
  AiOutlineCloseSquare,
} from 'react-icons/ai'
import FrameVideo from './FrameVideo'

const IntroButtons = () => {
  const [showVideo, setShowVideo] = useState(false)
  const { t } = useTranslation()
  return (
    <div className='introBtn'>
      <span>
        <a
          className='btn'
          href='https://drive.google.com/file/d/1krYUAkdyG2JZF327jIRj145XHkkmmkJb/view'
          target='_blank'
          rel='noreferrer'
        >
          {t('translation:whitePaper')}
          <AiFillFileWord />
        </a>
      </span>
      <span className='animation'></span>
      <button className='play' onClick={() => setShowVideo(true)}>
        <span className='playIcon'>
          <IoMdPlay />
        </span>
      </button>
      <span>
        <a
          className='btn'
          href='https://drive.google.com/file/d/1JL55LpwbJ_XmD-LRNbAwDeANQNIicXsf/view'
          target='_blank'
          rel='noreferrer'
        >
          {t('translation:onePager')}
          <AiOutlineFilePdf />
        </a>
      </span>
      {showVideo && (
        <div className='frame'>
          <AiOutlineCloseSquare onClick={() => setShowVideo(false)} />
          <FrameVideo />
        </div>
      )}
    </div>
  )
}

export default IntroButtons
