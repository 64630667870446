import { useState } from 'react'
import {
  FloatingMenu,
  MainButton,
  ChildButton,
} from 'react-floating-button-menu'

import {
  FaTelegram,
  FaUsers,
  FaBullhorn,
  FaTwitter,
  FaTwitterSquare,
  FaWhatsapp,
  FaFacebook,
  FaLink,
} from 'react-icons/fa'

const FloatingButtonMenu = () => {
  const [isOpen, setIsOpen] = useState(false)
  const goToSocialMedia = (value) => {
    if (value === 1) {
      window.open('https://telegram.me/promodexio_global')
    }
    if (value === 2) {
      window.open('https://telegram.me/promodexio')
    }
    if (value === 3) {
      window.open('https://twitter.com/promodexio')
    }
    if (value === 4) {
      window.open('https://linktr.ee/promodexio')
    }
    if (value === 5) {
      window.open(
        'https://twitter.com/intent/tweet?url=&text=Promodex.io%20looks%20great%20project%20worth%20checking.%20You%20can%20see%20all%20the%20details%20on%20https%3A%2F%2Flinktr.ee%2Fpromodexio&via=&hashtags='
      )
    }
    if (value === 6) {
      window.open(
        'https://api.whatsapp.com/send?text=Promodex.io%20looks%20great%20project%20worth%20checking.%20https%3A%2F%2Flinktr.ee%2Fpromodexio'
      )
    }
    if (value === 7) {
      window.open(
        'https://www.facebook.com/login.php?skip_api_login=1&api_key=966242223397117&signed_next=1&next=https%3A%2F%2Fwww.facebook.com%2Fsharer.php%3Fu%3Dhttps%253A%252F%252Fpromodex.io%252F%26t%3DCheck%2Bthis%2Bout%2521%2Bhttps%253A%252F%252Fpromodex.io%252F&cancel_url=https%3A%2F%2Fwww.facebook.com%2Fdialog%2Fclose_window%2F%3Fapp_id%3D966242223397117%26connect%3D0%23_%3D_&display=popup&locale=en_GB'
      )
    }
  }
  return (
    <div className='floatingMenu'>
      <FloatingMenu slideSpeed={500} direction='up' spacing={8} isOpen={isOpen}>
        <MainButton
          iconResting={<FaUsers style={{ fontSize: 32 }} color='white' />}
          iconActive={<FaUsers style={{ fontSize: 32 }} color='white' />}
          background='#5340ff'
          onClick={() => setIsOpen(!isOpen)}
          size={56}
        />
        <ChildButton
          icon={<FaTelegram color='white' style={{ fontSize: 22 }} />}
          background='rgb(0 136 204)'
          size={40}
          onClick={() => goToSocialMedia(1)}
        />
        <ChildButton
          icon={<FaBullhorn color='white' style={{ fontSize: 20 }} />}
          background='rgb(0 136 204)'
          size={40}
          onClick={() => goToSocialMedia(2)}
        />
        <ChildButton
          icon={<FaTwitter color='white' style={{ fontSize: 20 }} />}
          background='rgb(29, 161, 242)'
          size={40}
          onClick={() => goToSocialMedia(3)}
        />
        <ChildButton
          icon={<FaLink color='white' style={{ fontSize: 20 }} />}
          background='rgb(254, 0, 94)'
          size={40}
          onClick={() => goToSocialMedia(4)}
        />
        <ChildButton
          icon={<FaTwitterSquare color='white' style={{ fontSize: 20 }} />}
          background='rgb(29, 161, 242)'
          size={40}
          onClick={() => goToSocialMedia(5)}
        />
        <ChildButton
          icon={<FaWhatsapp color='white' style={{ fontSize: 20 }} />}
          background='rgb(37, 211, 102)'
          size={40}
          onClick={() => goToSocialMedia(6)}
        />
        <ChildButton
          icon={<FaFacebook color='white' style={{ fontSize: 20 }} />}
          background='rgb(66, 103, 178)'
          size={40}
          onClick={() => goToSocialMedia(7)}
        />
      </FloatingMenu>
    </div>
  )
}

export default FloatingButtonMenu
