import Community from './Community'
import Navbar from './Navbar'
import SelectLanguage from './SelectLanguage'
const Header = () => {
  return (
    <header>
      <div className='header-top'>
        <SelectLanguage />
        <Community />
      </div>
      <Navbar />
    </header>
  )
}

export default Header
