const Road = () => {
  return (
    <div className='roadQuarters'>
      <div className='quarter qfirst'>2022 Q1-Q4</div>
      <div className='quarter qsecond'>2023 Q1-Q4</div>
      <div className='quarter qthird'>2024 Q1-Q2</div>
      <div className='quarter qthird-2'>2024 Q3-Q4</div>
      <div className='quarter qfourth'>2025 Q1-Q2</div>
      <div className='quarter qfifth'>2025 Q3-Q4</div>
    </div>
  )
}

export default Road
