import { useState, useRef, useEffect } from 'react'
import headerLogo from '../../assets/logo/logo.png'
import airdropIcon from '../../assets/header-btn.svg'
import { FiMenu } from 'react-icons/fi'
import { AiFillCloseCircle } from 'react-icons/ai'
import { useTranslation } from 'react-i18next'
import OutsideClick from '../utils/OutsideClick'
import SelectLanguage from './SelectLanguage'
import Community from './Community'
const Navbar = () => {
  const { t } = useTranslation()
  const [showMenu, setShowMenu] = useState(false)
  const [isScrolled, setIsScrolled] = useState(false)
  const navRef = useRef()

  const handleClickOutside = () => {
    setShowMenu(false)
  }
  const handleClick = () => {
    setShowMenu(!showMenu)
  }
  const handleScroll = () => {
    const offset = window.scrollY
    if (offset > 100) {
      setIsScrolled(true)
    } else {
      setIsScrolled(false)
    }
  }
  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
  })
  OutsideClick(handleClickOutside, navRef)

  return (
    <nav className={`navbar ${isScrolled ? 'sticky' : ''}`} ref={navRef}>
      <ul className='menu'>
        <li>
          <a href='#home'>
            <img src={headerLogo} className='headerLogo' alt='headerLogo' />
          </a>
        </li>
        <li>
          <a href='#howItWorks'>{t('translation:about')}</a>
        </li>
        <li>
          <a href='#roadmap'>{t('translation:roadmap')}</a>
        </li>
        <li>
          <a href='#token'>{t('translation:token')}</a>
        </li>
        <li>
          <a href='#fundraise'>{t('translation:fundraise')}</a>
        </li>
        <li>
          <a href='#team'>{t('translation:team')}</a>
        </li>
        <li>
          <a href='#partners'>{t('translation:partners')}</a>
        </li>
        <li className='buyBtn'>
          <a href='#fundraise' className='buyPromo'>
            {t('translation:buynow')}
            <img src={airdropIcon} alt='airdropIcon' width={17} height={21} style={{ marginRight: 5 }} />
          </a>
        </li>
      </ul>

      {!showMenu && (
        <div className='mobilHeader'>
          <a href='#home'>
            <img src={headerLogo} className='headerLogo' alt='headerLogo' />
          </a>
          <FiMenu className={`barIcon`} onClick={handleClick} />
        </div>
      )}

      {showMenu && (
        <div className='mobilMenu'>
          <AiFillCloseCircle className='closeBtn' onClick={handleClick} />
          <div className='header-mobil-top'>
            <SelectLanguage />
            <Community />
          </div>
          <ul>
            <li onClick={handleClick}>
              <a href='#home' onClick={handleClick}>
                <img src={headerLogo} className='headerLogo' alt='headerLogo' />
              </a>
            </li>
            <li>
            <a href='#howItWorks' onClick={handleClick}>{t('translation:about')}</a>
            </li>
            <li>
            <a href='#roadmap' onClick={handleClick}>{t('translation:roadmap')}</a>
            </li>
            <li>
              <a href='#token' onClick={handleClick}>
                {t('translation:token')}
              </a>
            </li>
            <li>
              <a href='#fundraise' onClick={handleClick}>{t('translation:fundraise')}</a>
            </li>
            <li>
            <a href='#team' onClick={handleClick}>{t('translation:team')}</a>
            </li>
            <li>
            <a href='#partners' onClick={handleClick}>{t('translation:partners')}</a>
            </li>
            <li className='buyBtn'>
              <a href='#fundraise' className='buyPromo'>
                {t('translation:buynow')}
                <img src={airdropIcon} alt='airdropIcon' width={17} height={21} style={{ marginRight: 5 }} />
              </a>
            </li>
          </ul>
        </div>
      )}
    </nav>
  )
}

export default Navbar
