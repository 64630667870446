import { useTranslation } from 'react-i18next'
import IntroButtons from './IntroButtons'
import poolz from '../../../assets/platforms/poolz.svg'
import kommunitas from '../../../assets/platforms/kommunitas.svg'
import spres from '../../../assets/platforms/spres.svg'
import whitebitTr from '../../../assets/platforms/whitebit-flag.svg'
import kdg from '../../../assets/platforms/kdg.svg'
import trustFi from '../../../assets/platforms/trustfi.svg'
import ixir from '../../../assets/platforms/ixir.svg'
import whitebit from '../../../assets/platforms/whitebit.svg'
import dotIcon from '../../../assets/dot.svg'
import { GiClick } from 'react-icons/gi'

const Introduction = () => {
  const { t } = useTranslation()

  const platforms = [
    {
      name: 'poolz',
      image: poolz
    },
    {
      name: 'komunitas',
      image: kommunitas
    },
    {
      name: 'spres',
      image: spres
    },
    {
      name: 'whitebit-tr',
      image: whitebitTr
    },
    {
      name: 'kdg',
      image: kdg
    },
    {
      name: 'trustFi',
      image: trustFi
    },
    {
      name: 'ixir',
      image: ixir
    },
    {
      name: 'whitebit',
      image: whitebit
    }
  ]
  return (
    <section id='home' className='introduction'>
      <div className='introText'>
        <div className='introHead'>
          <h1>{t('translation:intro-header')}</h1>
          {/* <p>{t('translation:headerParagraph')}</p> */}
          {/* <p className='marginZero'>{t('translation:headerParagraph2')}</p> */}
        </div>
        <li className='intro-list'>
          <ul><img src={dotIcon} alt='dot' style={{ marginRight: 5 }} /> AI-Based Social Media Account Grading Algorithm</ul>
          <ul><img src={dotIcon} alt='dot' style={{ marginRight: 5 }} /> <b>WEB3-Based</b> Algorithmic Rewarding on Smart Contract</ul>
        </li>
        <div className='intro-boxes'>
          <div className='intro-box-1'>
            <span className='intro-boxes__title'>Are you a <a href='https://www.youtube.com/watch?v=8jmmOy_QE_A' target='_blank' rel="noreferrer">campaigner?</a></span>
            <li>
              <ul><img src={dotIcon} alt='dot' /> Define the participant profile</ul>
              <ul><img src={dotIcon} alt='dot' /> Outline your campaign</ul>
              <ul><img src={dotIcon} alt='dot' /> Lock the total budget on chain</ul>
            </li>
          </div>
          <div className='intro-box-2'>
            <span className='intro-boxes__title'>Are you a <a href='https://www.youtube.com/watch?v=4Ml73PfMBmk' target='_blank' rel="noreferrer">Promoter/KOL/Influencer?</a></span>
            <li>
              <ul><img src={dotIcon} alt='dot' /> See the campaign on your dashboard</ul>
              <ul><img src={dotIcon} alt='dot' /> Participate in the campaign</ul>
              <ul><img src={dotIcon} alt='dot' /> Receive your reward on your wallet</ul>
            </li>
          </div>
        </div>

        <IntroButtons />
      </div>
      <div className='sale-platforms-card'>
        <div className='sale-platforms-card__header'>
          <span className='sale-platforms-card__header__title'>$PROMO TOKEN</span>
          <span className='sale-platforms-card__header__border-text'>&nbsp;&nbsp; Sale Platforms &nbsp;&nbsp;</span>
        </div>
        <div className='sale-platforms-card__content'>
          {platforms.map((platform, index) => (
            <a href='#fundraise' key={index}>
              <div className='sale-platforms-card__content__box'>
                <img src={platform.image} alt={platform.name} width={100} />
              </div>
            </a>
          ))}
        </div>
        <div className='seeDetails'>
          <a href='#fundraise'>
            <GiClick />
            {t('translation:seeDetails')}
          </a>
        </div>
      </div>
      {/* <div className='introImg'>
        <img src={HomeIntro} alt='promotion_marketplace_image' />
      </div> */}
    </section>
  )
}

export default Introduction
