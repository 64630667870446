import React from 'react'
import soldOut from '../../../assets/soldout.png'
import BuyNowButton from './BuyNowButton'

function FundraiseWhiteCard({ 
  label = "Total Allocation", 
  name, 
  header, 
  title, 
  isSoldOut, 
  isSoldOutLabel,
  price, 
  totalAllocation, 
  detailLink, 
  buyNowLink, 
  date, 
  dateLabel, 
  buttonName
 }) {
  return (
    <div className='card' style={{
      background: '#FFFFFF',
      border: '1px solid #D8D8D8',

    }}>
      <div className='cardHead' style={{
        background: '#FFFFFF',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        height: 40,
      }}>
        <img src={header} alt={name} width={130} />
      </div>
      <span style={{
        color: '#000659',
        fontWeight: 200
      }} className='border-text'>&nbsp;&nbsp; {title} &nbsp;&nbsp;</span>
      <div className='cardContent' style={{
        color: '#283D5C',
      }}>
        <h6 style={{
          marginTop: 20
        }}>1 PROMO = {price} USD</h6>
        <p style={{
          marginTop: 20,
          fontSize: 18,
          fontWeight: 200
        }}>
          {label}
        </p>
        <p style={{
          fontSize: label === 'Deposit Time' ? 18 : 24,
          fontWeight: 600,
          marginTop: '-15px'
        }}>
          {totalAllocation}
        </p>
        {detailLink && (<a target='_blank' href={detailLink} style={{
          display: 'block',
          textDecoration: 'none',
          fontSize: 16,
          fontWeight: 200,
          margin: '0 40px',
          border: '2px solid #5340FE50',
          color: '#5340FE',
          borderRadius: 10,
          marginTop: '-15px',
          position: 'relative',
          zIndex: 1000
        }} rel="noreferrer">See Details</a>)
        }
        {dateLabel && (
          <p style={{
            position: 'absolute',
            bottom: 60,
            left: 0,
            width: '100%',
            fontSize: 18,
            fontWeight: 400,
            color: '#283D5C'
          }}>
            {dateLabel}
          </p>)}
        <p style={{
          position: 'absolute',
          bottom: 20,
          left: 0,
          width: '100%',
          fontSize: dateLabel ? 16 : 18,
          fontWeight: 200,
          color: '#283D5C'
        }}><b>Date:</b> {date}</p>
        {/* <p style={{
          position: 'absolute',
          bottom: 20,
          left: 'calc(50% - 75px)',
          fontSize: 20,
          fontWeight: 200,
          color: '#283D5C'
        }}><b>Time:</b> {date}</p> */}
      </div>
      {isSoldOutLabel && (
        <img src={soldOut} alt='sold out' width={200} style={{
          position: 'absolute',
          top: 100,
          right: 20,
        }} />
      )}
      <BuyNowButton to={buyNowLink} soldOut={isSoldOut} label={buttonName} />
    </div>
  )
}

export default FundraiseWhiteCard