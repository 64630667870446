import Main from '../components/main/Main'
const Home = () => {
  return (
    <div className='home'>
      <Main />
    </div>
  )
}

export default Home
