import { IoChevronBackCircleSharp } from 'react-icons/io5'
import { useNavigate } from 'react-router-dom'

const UserAgreement = () => {
  const navigate = useNavigate()
  return (
    <div className='policies'>
      <div className='backHome'>
        <IoChevronBackCircleSharp onClick={() => navigate('/')} />
      </div>
      <h1>User Agreement</h1>
      <p>
        These Terms of Use constitute a legally binding agreement made between
        you, whether personally or on behalf of an entity (“you”) and Promodex
        (“Company,” “we,” “us,” or “our”), concerning your access to and use of
        the https://promodex.io/ website as well as any other media form, media
        channel, mobile website or mobile application related, linked, or
        otherwise connected thereto (collectively, the “Site”). You agree that
        by accessing the Site, you have read, understood, and agreed to be bound
        by all of these Terms of Use.{' '}
      </p>
      <p>
        IF YOU DO NOT AGREE WITH ALL OF THESE TERMS OF USE, THEN YOU ARE
        EXPRESSLY PROHIBITED FROM USING THE SITE AND YOU MUST DISCONTINUE USE
        IMMEDIATELY.
      </p>
      <p>
        Supplemental terms and conditions or documents that may be posted on the
        Site from time to time are hereby expressly incorporated herein by
        reference. We reserve the right, in our sole discretion, to make changes
        or modifications to these Terms of Use from time to time. We will alert
        you about any changes by updating the “Last updated” date of these Terms
        of Use, and you waive any right to receive specific notice of each such
        change. Please ensure that you check the applicable Terms every time you
        use our Site so that you understand which Terms apply. You will be
        subject to, and will be deemed to have been made aware of and to have
        accepted, the changes in any revised Terms of Use by your continued use
        of the Site after the date such revised Terms of Use are posted.
      </p>
      <p>
        The information provided on the Site is not intended for distribution to
        or use by any person or entity in any jurisdiction or country where such
        distribution or use would be contrary to law or regulation or which
        would subject us to any registration requirement within such
        jurisdiction or country. Accordingly, those persons who choose to access
        the Site from other locations do so on their own initiative and are
        solely responsible for compliance with local laws, if and to the extent
        local laws are applicable.
      </p>
      <p>
        The Site is intended for users who are at least 18 years old. Persons
        under the age of 18 are not permitted to use or register for the Site.{' '}
      </p>
      <h4>Definitions</h4>
      <p>
        -Affiliate: in relation to a party, refers to any other entity that is
        directly or indirectly (i) controlled by, (ii) controls or (iii) is
        under common control with that party, and in relation to the Company
        also includes any fund, limited partnership or other collective
        investment vehicle or any other person managed or advised by the
        Company’s team.
      </p>
      <p>-AML: Anti-Money Laundering.</p>
      <p>
        -Applicable Laws: Refers to laws, statutes, regulations, ordinances,
        treaties, guidelines and policies issued by governmental organizations
        or regulatory bodies, including but not limited to the applicable law
        set forth in the laws of Seychelles.
      </p>
      <p>
        -Promodex : Promodex Is A Blockchain Based Programmatic Promotion
        Marketplace
      </p>
      <p>
        -PromoToken: A blockchain-based token that can be issued, stored,
        transferred, and transacted across multiple networks.{' '}
      </p>
      <p>-CFT: Countering the Financing of Terrorism.</p>
      <p>
        -Confidential Information: Any non-public proprietary information or
        documents of the User or Company (whether written, oral or otherwise)
        disclosed to Company by or on behalf of the User that, when disclosed in
        tangible or intangible form, is marked as confidential (including in
        visual, oral or electronic form) and relates to us or another User who
        was previously a User and is provided or disclosed by Company (or to
        employees or agents) in connection with the use of or participation in
        the Services.
      </p>
      <p>
        -Content: any Company-generated content, including logos, identifying
        marks, images, illustrations, designs, icons, photographs, videos, text,
        written or multimedia materials, services, advertisements, software,
        code, data, files, archives, folders or available downloads on the
        Promodex.
      </p>
      <p>
        -Cookies: The small text files that are stored on your computer by the
        website you visit. They are often used to make websites work or work
        more efficiently and to provide information to the website owner.
      </p>
      <p>-FCFS: first come, first served.</p>
      <p>
        -Prohibited Jurisdictions: United States of America, Canada, New
        Zealand, Iraq, Islamic Republic of Iran, North Korea, Afghanistan,
        Yemen, Syria, Libya, South Sudan, Palestine, Bahrain, Qatar, People’s
        Republic of China (excluding the Special Administrative Regions of Hong
        Kong and Macao and the territory of Taiwan), Thailand and the Socialist
        Republic of Vietnam.
      </p>
      <p>
        -Services: Refers to the services provided to users through the Website.
      </p>

      <p>
        -Wallet: refers to a blockchain wallet that must be compatible with the
        networks on which Promodex is active, such as MetaMask.
      </p>
      <h3>GENERAL PROVISIONS</h3>
      <h5>CONTRACTUAL RELATIONSHIP</h5>
      <p>
        These Terms constitute a valid and binding agreement between you and the
        Company. The binding obligations set forth in these Terms are
        enforceable.
      </p>
      <h5>REVISION AND CHANGES</h5>
      <p>
        The Company reserves the right, at its sole discretion, to revise,
        modify or update any of the clauses and provisions set forth in these
        Terms at any time. The Company will give notice of any revision or
        modification of such clauses or provisions by updating these Terms and
        stating the “date of last revision” on a page of the Terms. All
        revisions and updates to these Terms will automatically become effective
        upon their posting on the Platform, which includes the Website.
        Therefore, if you continue to access or use the Promodex and/or the
        Services, you will be deemed to have read, understood and accepted all
        revised Terms and Conditions. If you do not agree with the revised or
        updated Terms and Conditions, you should immediately discontinue
        accessing the Website or using the Services. We encourage you to review
        these Terms and Conditions frequently and carefully to ensure that you
        understand them.
      </p>
      <h5>PRIVACY POLICY</h5>
      <p>
        You acknowledge that you have read, understand and agree to the Website
        Privacy Policy. This policy explains how the Company treats your
        information and protects your privacy when you access or use the
        Promodex.
      </p>
      <p>
        By using the Promodex, you consent to the Company collecting and using
        your information and anonymized data about your use of the Services for
        analytics, identifying trends, and statistical purposes to further
        improve the effectiveness and efficiency of the Services.
      </p>
      <p>
        You hereby expressly authorize Company to disclose any information about
        you in Company’s possession to law enforcement or government officials
        upon request of a court order.
      </p>
      <h5>LINKS TO AND FROM THE WEBSITE</h5>
      <p>
        You may access websites operated, provided or otherwise licensed by
        persons other than us (“Third Party Services”) through hypertext or
        other computer links from the Website. Such hyperlinks are provided for
        your convenience.
      </p>
      <p>
        A link from Third Party Services does not imply that Company endorses or
        approves of the content of such website or that Company is an operator
        of such website. You understand that you are solely responsible for
        determining the extent to which you may use or rely on any content on
        other third-party websites accessed from the Website. The Company has no
        control over, and assumes no responsibility for, the content on any such
        web sites or resources or any loss or damage arising from your use of
        them.
      </p>
      <p>
        The Company assumes no responsibility for the use of, or the inability
        to use, any third-party software, other materials or content posted
        and/or uploaded to any such website, and we assume no liability to any
        person or entity for the inaccuracy or incompleteness of any such
        third-party content. All intellectual property rights in and to the
        Third Party Services are owned by the respective third parties.
      </p>
      <h5>INFORMATION</h5>
      <p>
        You acknowledge that you, not us, are solely responsible for any
        submissions of all contents, remarks, suggestions, ideas, materials,
        feedbacks, or other information, including bug reports, in relation to
        the Services provided through the Website, including any submission to
        our social media platforms such as Twitter and Telegram, and that you,
        not us, are fully responsible for such submissions, including their
        accuracy, legality, reliability, appropriateness, originality, and
        copyrights. We will respond based on the information you have supplied,
        without verifying it. Regardless of the foregoing, we reserve the right
        to refuse to post, remove, edit, or abridge any submission for any
        reason, and to freely use, copy, disclose, publish, display, or exploit
        such submission as we deem necessary without any payment of royalties,
        acknowledgement, or consent, and we may keep copies of all information
        materials relevant to the Service.
      </p>
      <h5>INTELLECTUAL PROPERTY RIGHTS</h5>
      <p>
        Unless otherwise indicated, the Site is our proprietary property and all
        source code, databases, functionality, software, website designs, audio,
        video, text, photographs, and graphics on the Site (collectively, the
        “Content”) and the trademarks, service marks, and logos contained
        therein (the “Marks”) are owned or controlled by us or licensed to us,
        and are protected by copyright and trademark laws and various other
        intellectual property rights, international copyright laws, and
        international conventions. The Content and the Marks are provided on the
        Site “AS IS” for your information and personal use only.{' '}
      </p>
      <p>
        Except as expressly provided in these Terms of Use, no part of the Site
        and no Content or Marks may be copied, reproduced, aggregated,
        republished, uploaded, posted, publicly displayed, encoded, translated,
        transmitted, distributed, sold, licensed, or otherwise exploited for any
        commercial purpose whatsoever, without our express prior written
        permission.
      </p>
      <p>
        Provided that you are eligible to use the Site, you are granted a
        limited license to access and use the Site and to download or print a
        copy of any portion of the Content to which you have properly gained
        access solely for your personal, non-commercial use. We reserve all
        rights not expressly granted to you in and to the Site, the Content and
        the Marks.
      </p>
      <p>
        If and to the extent that any such intellectual property rights are
        vested in you by operation of law or otherwise, you agree to do all such
        acts and execute all such documents as we may reasonably request in
        order to assign such intellectual property rights back to us.
      </p>
      <h5>USER REPRESENTATIONS</h5>
      <p>
        By using the Site, you represent and warrant that: (1) all registration
        information you submit will be true, accurate, current, and complete;
        (2) you will maintain the accuracy of such information and promptly
        update such registration information as necessary; (3) you have the
        legal capacity and you agree to comply with these Terms of Use; (4) you
        are not a minor in the jurisdiction in which you reside; (5) you will
        not access the Site through automated or non-human means, whether
        through a bot, script, or otherwise; (6) you will not use the Site for
        any illegal or unauthorized purpose; and (7) your use of the Site will
        not violate any applicable law or regulation.
      </p>
      <p>
        If you provide any information that is untrue, inaccurate, not current,
        or incomplete, we have the right to suspend or terminate your account
        and refuse any and all current or future use of the Site (or any portion
        thereof).
      </p>
      <h5>USER REGISTRATION</h5>
      <p>
        You may be required to register with the Site. You agree to keep your
        password confidential and will be responsible for all use of your
        account and password. We reserve the right to remove, reclaim, or change
        a username you select if we determine, in our sole discretion, that such
        username is inappropriate, obscene, or otherwise objectionable.
      </p>
      <h5>IDENTITY VERIFICATION THROUGH KYC/AML/CFT PROCESS</h5>
      <p>
        The Company is implementing KYC/AML/CFT tools into the Company’s
        launchpad through Blockpass.org. Therefore, the Company reserves the
        right at any time, to ask for your:{' '}
      </p>
      <p>-personal information, </p>
      <p>-Name-Surname,</p>
      <p>-Birthday,</p>
      <p>-e-mail address, </p>
      <p>-nationality,</p>
      <p>-location, </p>

      <p>
        -government identification number (Identification Card/Passport number
        and Date of Identification Card/Passport issuing),{' '}
      </p>
      <p>-telegram username, </p>
      <p>-Promodex’s Wallet address, </p>
      <p>
        -and any KYC/AML/CFT documentation with the liveness test that it deems
        necessary to determine the identity and location of a User, and reserves
        the right to restrict Service and payment until the User’s identity is
        sufficiently determined;
      </p>
      <p>
        Promodex has all rights to share the submitted KYC/AML/CFT information
        and documentation to the third parties to verify the authenticity of the
        submitted information, and the end user (you) giving the consent to
        share such information by using the Services; and to reject the use of
        the Services that the Company has the reasonable ground to believe that
        they are found to be in violation of relevant and applicable AML/CFT
        laws and regulations, and to cooperate with the competent authorities or
        any investigation when and if necessary upon the valid request by the
        court order.
      </p>
      <p>
        In accordance with various jurisdictions’ laws, rules, and conventions,
        the Company clearly bans and rejects the use of the Service for any type
        of illegal conduct, including money laundering, terrorist funding, or
        trade sanctions breaches. As a result, the Services are not available to
        people or businesses on any Politically Exposed Persons (“PEP”) lists or
        on any US, EU, or other worldwide sanctions or watch lists. You
        represent that you are not on any of these lists by using the Services.
      </p>
      <p>
        You fully acknowledge that your information and KYC/AML/CFT
        documentation may be disclosed to government agencies or regulators upon
        a valid request of the court order. The Company will rely on the
        information you provided and should there be any reasonable grounds for
        the Company to believe that the partial or the whole of your information
        provided to us is incomplete, or incorrect, or outdated, the Company
        reserves the right to send you a notice to demand correction, or to
        delete such information directly, and, as the case may be, to disable
        you to access to all or part of the Website and the Services.
      </p>

      <p>
        If the Company has a reasonable ground to believe that any User
        transacts or uses the Services by using digital currencies derived from
        any suspicious illegal activities, the Company shall be entitled to
        freeze, close, or delete the User’s accounts as necessary. The Company
        will hold no liability to such users for any damage, or loss arising out
        of or in connection with this manner herein.
      </p>
      <h5>PROHIBITED ACTIVITIES</h5>
      <p>
        You may not access or use the Site for any purpose other than that for
        which we make the Site available. The Site may not be used in connection
        with any commercial endeavors except those that are specifically
        endorsed or approved by us.
      </p>
      <p>As a user of the Site, you agree not to:</p>
      <p>
        1. Systematically retrieve data or other content from the Site to create
        or compile, directly or indirectly, a collection, compilation, database,
        or directory without written permission from us.
      </p>
      <p>
        2. Trick, defraud, or mislead us and other users, especially in any
        attempt to learn sensitive account information such as user passwords.
      </p>
      <p>
        {' '}
        3. Circumvent, disable, or otherwise interfere with security-related
        features of the Site, including features that prevent or restrict the
        use or copying of any Content or enforce limitations on the use of the
        Site and/or the Content contained therein.
      </p>
      <p>
        4. Disparage, tarnish, or otherwise harm, in our opinion, us and/or the
        Site.{' '}
      </p>
      <p>
        5. Use any information obtained from the Site in order to harass, abuse,
        or harm another person.
      </p>
      <p>
        6. Make improper use of our support services or submit false reports of
        abuse or misconduct.
      </p>
      <p>
        7. Use the Site in a manner inconsistent with any applicable laws or
        regulations.
      </p>
      <p>8. Engage in unauthorized framing of or linking to the Site.</p>
      <p>
        9. Upload or transmit (or attempt to upload or to transmit) viruses,
        Trojan horses, or other material, including excessive use of capital
        letters and spamming (continuous posting of repetitive text), that
        interferes with any party’s uninterrupted use and enjoyment of the Site
        or modifies, impairs, disrupts, alters, or interferes with the use,
        features, functions, operation, or maintenance of the Site.
      </p>
      <p>
        10. Engage in any automated use of the system, such as using scripts to
        send comments or messages, or using any data mining, robots, or similar
        data gathering and extraction tools.
      </p>
      <p>
        11. Delete the copyright or other proprietary rights notice from any
        Content.
      </p>
      <p>
        12. Attempt to impersonate another user or person or use the username of
        another user.
      </p>
      <p>
        13. Upload or transmit (or attempt to upload or to transmit) any
        material that acts as a passive or active information collection or
        transmission mechanism, including without limitation, clear graphics
        interchange formats (“gifs”), 1×1 pixels, web bugs, cookies, or other
        similar devices (sometimes referred to as “spyware” or “passive
        collection mechanisms” or “pcms”).
      </p>
      <p>
        14. Interfere with, disrupt, or create an undue burden on the Site or
        the networks or services connected to the Site.
      </p>
      <p>
        15. Harass, annoy, intimidate, or threaten any of our employees or
        agents engaged in providing any portion of the Site to you.
      </p>
      <p>
        16. Attempt to bypass any measures of the Site designed to prevent or
        restrict access to the Site, or any portion of the Site.
      </p>

      <p>
        17. Copy or adapt the Site’s software, including but not limited to
        Flash, PHP, HTML, JavaScript, or other code.
      </p>
      <p>
        18. Except as permitted by applicable law, decipher, decompile,
        disassemble, or reverse engineer any of the software comprising or in
        any way making up a part of the Site.
      </p>
      <p>
        19. Except as may be the result of standard search engine or Internet
        browser usage, use, launch, develop, or distribute any automated system,
        including without limitation, any spider, robot, cheat utility, scraper,
        or offline reader that accesses the Site, or using or launching any
        unauthorized script or other software.
      </p>
      <p>
        20. Use a buying agent or purchasing agent to make purchases on the
        Site.
      </p>
      <p>
        21. Make any unauthorized use of the Site, including collecting
        usernames and/or email addresses of users by electronic or other means
        for the purpose of sending unsolicited email, or creating user accounts
        by automated means or under false pretenses.
      </p>
      <p>
        22. Use the Site as part of any effort to compete with us or otherwise
        use the Site and/or the Content for any revenue-generating endeavor or
        commercial enterprise.
      </p>
      <p>23. Use the Site to advertise or offer to sell goods and services.</p>
      <p>24. Sell or otherwise transfer your profile.</p>
      <p>25. Place the bid on his or her own Crypto-object Auction.</p>
      <h5>RISK DISCLOSURE</h5>
      <p>
        By accessing the Website or using or participating in the Company
        Services, you expressly acknowledge and assume the following risks:
      </p>
      <p>Risk of loss in value</p>
      <p>
        Tokens or any digital currencies are not issued by any central banks or
        national, supra-national, or quasi-national organizations. They are also
        not backed by any hard assets or other credit. The value of tokens or
        any digital currencies is affected by several factors, including but not
        limited to, the total number of tokens or any digital currencies in
        existence, the continued willingness of market participants to exchange
        government-issued currency for tokens or digital currencies ,
        purchasers’ expectations with respect to the rate of inflation of fiat
        currencies, purchasers’ expectations with respect to the rate of
        deflation of cryptocurrencies, interest rates, currency exchange rates,
        cyber theft of cryptocurrencies from online digital wallet providers, or
        news of such theft from such providers or individuals’ digital wallets,
        investment and trading activities of large investors, monetary policies
        of the governments, trade restrictions, currency devaluations and
        revaluations, regulatory measures, the global or regional political,
        economic or financial events and situations. Thus, all these factors
        will affect the value of tokens or digital currencies, which may result
        in the permanent partial or total loss of the value of the Company, a
        particular tokens or digital currency. No one will be obliged to
        guarantee the liquidity or the market price of any of the Promo Token or
        digital currencies maintained into your Wallets. The volatility and
        unpredictability of the value of tokens or digital currencies relative
        to the government-issued currency may result in a significant loss over
        a short period of time.
      </p>
      <p>The regulatory regime governing tokens or digital currencies</p>
      <p>
        The regulatory framework relating to tokens or digital currencies
        remains unsettled, and any laws, regulations, or guidelines may be
        significantly revised and amended which will materially and adversely
        affect the value of tokens or digital currencies and your services on
        hhttps://promodex.io/{' '}
      </p>
      <p>
        The Company will have no liability for any delay, error, interruption,
        or failure to perform any obligation under these Terms where the delay
        or failure is directly or indirectly resulting from any causes beyond
        the Company’s control, including, but not limited to:
      </p>
      <p>-Acts of God, nature, court, or government.</p>
      <p>
        Failure or interruption of public or private telecommunication networks,
        the failure of Binance Smart Chain Network, communication channels or
        information systems.
      </p>
      <p>
        -Acts or omission of a party for whom the Company is not responsible.
      </p>
      <p>
        -Delay, failure, or interruption in, or unavailability of, third-party
        services; and
      </p>
      <p>
        Strikes, lockouts, labor disputes, wars, embargoes, terrorist acts and
        riots.
      </p>
      <p>
        You understand and agree that your use of the services and the website
        is completely at your own risk. This clause is not exhaustive and does
        not disclose all the risks associated with digital currencies and the
        use of services. Therefore, you are recommended to carefully consider
        whether such use is suitable for you considering your judgement,
        circumstances, and financial position.
      </p>
      <h5>SUBMISSIONS</h5>
      <p>
        You acknowledge and agree that any questions, comments, suggestions,
        ideas, feedback, or other information regarding the Site (“Submissions”)
        provided by you to us are non-confidential and shall become our sole
        property. We shall own exclusive rights, including all intellectual
        property rights, and shall be entitled to the unrestricted use and
        dissemination of these Submissions for any lawful purpose, commercial or
        otherwise, without acknowledgment or compensation to you. You hereby
        waive all moral rights to any such Submissions, and you hereby warrant
        that any such Submissions are original with you or that you have the
        right to submit such Submissions. You agree there shall be no recourse
        against us for any alleged or actual infringement or misappropriation of
        any proprietary right in your Submissions
      </p>
      <h5>SITE MANAGEMENT</h5>
      <p>
        We reserve the right, but not the obligation, to: (1) monitor the Site
        for violations of these Terms of Use; (2) take appropriate legal action
        against anyone who, in our sole discretion, violates the law or these
        Terms of Use, including without limitation, reporting such user to law
        enforcement authorities; (3) in our sole discretion and without
        limitation, refuse, restrict access to, limit the availability of, or
        disable (to the extent technologically feasible) any of your
        Contributions or any portion thereof; (4) in our sole discretion and
        without limitation, notice, or liability, to remove from the Site or
        otherwise disable all files and content that are excessive in size or
        are in any way burdensome to our systems; and (5) otherwise manage the
        Site in a manner designed to protect our rights and property and to
        facilitate the proper functioning of the Site.
      </p>
      <h5>TERM AND TERMINATION</h5>
      <p>
        These Terms of Use shall remain in full force and effect while you use
        the Site. WITHOUT LIMITING ANY OTHER PROVISION OF THESE TERMS OF USE, WE
        RESERVE THE RIGHT TO, IN OUR SOLE DISCRETION AND WITHOUT NOTICE OR
        LIABILITY, DENY ACCESS TO AND USE OF THE SITE (INCLUDING BLOCKING
        CERTAIN IP ADDRESSES), TO ANY PERSON FOR ANY REASON OR FOR NO REASON,
        INCLUDING WITHOUT LIMITATION FOR BREACH OF ANY REPRESENTATION, WARRANTY,
        OR COVENANT CONTAINED IN THESE TERMS OF USE OR OF ANY APPLICABLE LAW OR
        REGULATION. WE MAY TERMINATE YOUR USE OR PARTICIPATION IN THE SITE OR
        DELETE YOUR ACCOUNT AND ANY CONTENT OR INFORMATION THAT YOU POSTED AT
        ANY TIME, WITHOUT WARNING, IN OUR SOLE DISCRETION.
      </p>
      <p>
        If we terminate or suspend your account for any reason, you are
        prohibited from registering and creating a new account under your name,
        a fake or borrowed name, or the name of any third party, even if you may
        be acting on behalf of the third party. In addition to terminating or
        suspending your account, we reserve the right to take appropriate legal
        action, including without limitation pursuing civil, criminal, and
        injunctive redress.
      </p>
      <h5>MODIFICATIONS AND INTERRUPTIONS</h5>
      <p>
        We reserve the right to change, modify, or remove the contents of the
        Site at any time or for any reason at our sole discretion without
        notice. However, we have no obligation to update any information on our
        Site. We also reserve the right to modify or discontinue all or part of
        the Site without notice at any time. We will not be liable to you or any
        third party for any modification, price change, suspension, or
        discontinuance of the Site.{' '}
      </p>
      <p>
        We cannot guarantee the Site will be available at all times. We may
        experience hardware, software, or other problems or need to perform
        maintenance related to the Site, resulting in interruptions, delays, or
        errors. We reserve the right to change, revise, update, suspend,
        discontinue, or otherwise modify the Site at any time or for any reason
        without notice to you. You agree that we have no liability whatsoever
        for any loss, damage, or inconvenience caused by your inability to
        access or use the Site during any downtime or discontinuance of the
        Site. Nothing in these Terms of Use will be construed to obligate us to
        maintain and support the Site or to supply any corrections, updates, or
        releases in connection therewith.
      </p>
      <h5>DISPUTE RESOLUTION</h5>
      <p>
        PLEASE READ THIS SECTION CAREFULLY AS IT INVOLVES A WAIVER OF CERTAIN
        RIGHTS TO BRING LEGAL PROCEEDINGS, INCLUDING A CLASS ACTION.
      </p>
      <h5>Notice of Claim and Dispute Resolution Period</h5>
      <p>
        Please contact the Company first. The Company will seek to address your
        concerns without resorting to formal legal proceedings whenever
        possible. If you have a dispute with the Company, you should contact the
        Company, and a case number will be assigned. The Company will attempt to
        resolve your dispute internally as soon as possible. The parties will
        agree to negotiate in good faith to resolve the dispute and discussions
        will remain confidential and subject to applicable laws protecting
        settlement discussions from use as evidence in any legal proceeding.
      </p>
      <h5>Agreement To Arbitrate</h5>
      <p>
        You and the Company agree that subject to paragraph 16.1 above, any
        dispute, claim, or controversy between you and the Company that arises
        in connection with, or relating in any way, to these Terms, or to your
        relationship with the Company as a user of the Services (whether by
        contract, tort, statute, fraud, misrepresentation, or any other legal
        theory, and whether the claims arise during or after the termination of
        these Terms) will be determined by a mandatory final and binding
        individual arbitration rather than a class action, except as set forth
        below under Exceptions to the Agreement to Arbitrate. You and the
        Company further agree that the arbitrator will have the exclusive power
        to rule on his or her jurisdiction, including, without limitation, any
        objections with respect to the existence, scope, or validity of the
        Agreement to Arbitrate, or to the arbitrability of any claim or
        counterclaim. Arbitration is more informal than a lawsuit in court.
        THERE IS NO JUDGE OR JURY IN ARBITRATION, AND COURT REVIEW OF AN
        ARBITRATION AWARD IS LIMITED. There may be more limited discovery than
        in court. The arbitrator must follow this agreement and can award the
        same damages and relief as a court, including, if applicable, attorney
        fees, except the arbitrator may not award declaratory or injunctive
        relief benefiting anyone but the parties to the arbitration. The
        arbitration provisions set forth in Clause 17 will survive termination
        of these Terms
      </p>
      <h5>Arbitration Rules</h5>
      <p>
        Any dispute arising out of or in connection with these Terms including
        any question regarding its existence, validity or termination, shall be
        referred to and finally resolved by arbitration administered by the
        Seychelles International Arbitration Centre (“SIAC”) in accordance with
        the Arbitration Rules of the Seychelles International Arbitration Centre
        (“SIAC Rules”) for the time being in force, which rules are deemed to be
        incorporated by reference in these Terms. The seat of the arbitration
        shall be Seychelles. The Tribunal shall consist of one arbitrator. The
        language of the arbitration shall be English
      </p>
      <h5>Governing Law/Jurisdiction</h5>
      <p>
        The governing law of the arbitration will be that of the Seychelles.
      </p>
      <h5>Confidentiality</h5>
      <p>
        The parties agree that the arbitration will be kept confidential. The
        existence of the arbitration, any non-public information provided in the
        arbitration, and any submissions, orders or awards made in the
        arbitration will not be disclosed to any non-parties except the
        tribunal, the parties, their counsel, experts, witnesses, accountants
        and auditors, insurers and reinsurers, and any other person necessary to
        facilitate the arbitration. Notwithstanding the preceding, a party may
        disclose information to the extent that disclosure may be required to
        fulfil a legal duty, protect, or pursue a legal right, or enforce or
        challenge an award in bona fide legal proceedings. This confidentiality
        provision will survive the termination of these Terms and any
        arbitration brought under these Terms.
      </p>
      <h5>Class Action Waiver</h5>
      <p>
        You and the Company agree that any claims relevant to these Terms, or
        your relationship with the Company will be brought against the other
        party in arbitration on an individual basis only and not as a plaintiff
        or class member in a purported class or representative action. You and
        the Company further agree to waive any right for such claims to be
        brought, heard, or arbitrated as a class, collective, representative, or
        private attorney general action, to the extent permissible by applicable
        laws. Combining or consolidating individual arbitrations into a single
        arbitration is not permitted without the consent of all parties
        involved.
      </p>
      <p>
        The Company reserves the right to update, modify, revise, suspend, or
        make future changes regarding the parties’ Agreement to Arbitrate,
        subject to applicable laws. You hereby consent and agree that it is your
        responsibility to ensure that your understanding of this Clause is up to
        date.{' '}
      </p>
      <p>
        Subject to applicable laws, your continued use of your Promodex’s
        account will be interpreted as your acceptance of any modifications
        regarding the parties’ Agreement to Arbitrate. You agree that if you
        object to the modifications, the Company may block access to your
        account pending closure of your account. In such circumstances, these
        Terms prior to modification will remain in full force and affect the
        pending closure of your accessibility.
      </p>
      <h5>CORRECTIONS</h5>
      <p>
        There may be information on the Site that contains typographical errors,
        inaccuracies, or omissions, including descriptions, pricing,
        availability, and various other information. We reserve the right to
        correct any errors, inaccuracies, or omissions and to change or update
        the information on the Site at any time, without prior notice.
      </p>
      <h5>DISCLAIMER OF ACCESSIBILITY OF THE WEBSITE AND THE SERVICES</h5>
      <p>
        The Website facilitates user access to the Project Tokens only and makes
        no warranty or representation to you regarding the quality, value,
        specifications, fitness for purpose, completeness or accuracy of the
        technology or infrastructure of the Tokens.
      </p>
      <p>
        To the extent permitted by applicable law, Company’s website and
        services are provided on the basis of ‘AS IS’ and ‘AS AVAILABLE’.
        Company does not guarantee that the features and functions contained on
        the Website and Services will meet your requirements. You hereby agree
        and acknowledge that your access to and use of the Site and Services is
        at your own risk and that you are liable for any and all
        responsibilities and consequences arising out of or in connection with
        your use or accessibility of the Site and Services. You expressly agree
        that the Company shall have no liability in this regard.
      </p>
      <p>
        You hereby agree and acknowledge that the Website may contain links to
        third party websites or services that are not owned or controlled by the
        Company, so that the Company assumes no responsibility for the content
        or services, goods or activities offered by such third party websites.
        You further agree that the Company shall not be liable, directly or
        indirectly, for any damage, loss or consequence arising out of or in
        connection with the use of any content, materials or services available
        through the Third Party Website.
      </p>
      <p>
        The Company reserves the right to limit the availability of the Website
        to any person, geographic area or jurisdiction if we so choose and/or to
        terminate your access to and use of the Website and the Services at any
        time and in our sole discretion.
      </p>
      <p>
        Company may, in its sole discretion, restrict or limit your use of the
        Website. In addition, for commercial, security, technical, maintenance,
        legal or regulatory reasons, or due to a breach of these Terms, we may
        terminate the Website or your access to the Website and the Services at
        any time and without notice to you.
      </p>
      <h5>LIMITATIONS OF LIABILITY</h5>
      <p>
        Notwithstanding any provisions within these terms, in no event will the
        company, its partners its affiliate, or its employees, agents, officers,
        or directors be liable to the user for any incidental, special,
        exemplary, punitive, indirect, or consequential damages of any kind,
        under any legal theory, arising out of or in connection with your use,
        or inability to use, the website, any websites linked to it, any content
        on the website or other such websites, or any other services or items
        obtained through the website, or other such websites, including, but not
        limited to, lost revenue, lost profits, loss of business or anticipated
        savings, loss of use, loss of goodwill, loss of data, loss of your
        credential information, loss or interruption of technology, loss of use
        of service or equipment, even if the user was advised of the possibility
        of such damages, and whether arising under a theory of contract, tort,
        strict liability or otherwise. The company will also have no liability
        for any emergency and/or unforeseeable incidents related to your use of
        the services on the Promodex platform such as stolen private key or
        hacked accounts.
      </p>
      <p>
        Except as expressly provided in these Terms, and to the maximum extent
        permitted by any Applicable Laws, we disclaim all other representations
        or warranties, express or implied, made to you, your affiliates, or any
        other person, including, without limitation, any warranties regarding
        the quality, suitability, merchantability, fitness for a particular
        purpose or otherwise (regardless of any course of dealing, custom or
        usage of trade) of any service provided incidental to the Services under
        these Terms.
      </p>

      <p>
        In no event will our aggregate liability for any loss or damage that
        arises in connection with the Services exceed the purchase amount you
        paid to participate in the Service, if any, during a twelve (12) month
        period immediately preceding the event that gave rise to the claim for
        liability. The preceding limitations of liability will apply to the
        fullest actual amount you paid to participate in or access in the
        Service.
      </p>
      <p>
        Except as expressly provided in these Terms, and to the fullest extent
        permitted by any Applicable Laws, the Company, its Affiliate, and its
        related parties each disclaim all liability to you for any loss or
        damage arising out of or due to:
      </p>
      <p>
        -your use of inability to use, or availability or unavailability of the
        Services, including any Third-Party Services made available through the
        Services;
      </p>

      <p>
        -the occurrence or existence of any defect, interruption, deletion of
        files, delays in the operation or transmission of information to, from,
        or through the Services, communications failure, theft, destruction, or
        unauthorized access to Promodex’s records, programs, services, server,
        or other infrastructure relating to the Services.
      </p>
      <p>-the Services being infected with any malicious code or viruses; or</p>
      <p>-the failure of the Services to remain operational for any period.</p>
      <h5>INDEMNIFICATION</h5>
      <p>
        You irrevocably undertake the responsibility of fully indemnifying and
        holding harmless each of the Company, its Affiliate, licensors,
        shareholders, officers, directors, managers, employees, and agents from
        and against any and all losses, claims, actions, proceedings, damages,
        demands, judgements, sums, liabilities, damages, costs, charges and
        expenses, including, but not limited to, any reasonable attorney’s fees
        or penalties imposed by any regulatory authority, and reimbursements
        arising out of or related to the following situations:
      </p>
      <p>
        -Your use or any person using the Services on your behalf or
        participation in accordance with the Services on the Website or the
        Promodex.
      </p>
      <p>-Your breach of or our enforcement of these Terms.</p>
      <p>
        -Any violations of Applicable Laws, regulation, or rights of any
        third-party during your use or participation in the Service.
      </p>
      <p>
        -If you are obligated to indemnify the Company, its Affiliate,
        shareholders, licensors, officers, directors, managers, employees, and
        agents, the Company will have the right, at our sole discretion, to
        control any action or proceeding and to determine whether Promodex
        wishes to proceed, or settle, and if so, on what terms or provisions.
      </p>
      <h5>USER DATA</h5>
      <p>
        We will maintain certain data that you transmit to the Site for the
        purpose of managing the performance of the Site, as well as data
        relating to your use of the Site. Although we perform regular routine
        backups of data, you are solely responsible for all data that you
        transmit or that relates to any activity you have undertaken using the
        Site. You agree that we shall have no liability to you for any loss or
        corruption of any such data, and you hereby waive any right of action
        against us arising from any such loss or corruption of such data.
      </p>
      <h5>ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND SIGNATURES</h5>
      <p>
        Visiting the Site, sending us emails, and completing online forms
        constitute electronic communications. You consent to receive electronic
        communications, and you agree that all agreements, notices, disclosures,
        and other communications we provide to you electronically, via email and
        on the Site, satisfy any legal requirement that such communication be in
        writing. YOU HEREBY AGREE TO THE USE OF ELECTRONIC SIGNATURES,
        CONTRACTS, ORDERS, AND OTHER RECORDS, AND TO ELECTRONIC DELIVERY OF
        NOTICES, POLICIES, AND RECORDS OF TRANSACTIONS INITIATED OR COMPLETED BY
        US OR VIA THE SITE. You hereby waive any rights or requirements under
        any statutes, regulations, rules, ordinances, or other laws in any
        jurisdiction which require an original signature or delivery or
        retention of non-electronic records, or to payments or the granting of
        credits by any means other than electronic means.{' '}
      </p>
      <h5>LANGUAGE </h5>
      <p>
        Only English versions of any Promodex Team communications shall be
        considered official communications of the Company. The English version
        of any communications and/or these Terms shall prevail in case of
        differences in translation.
      </p>
      <h5>MISCELLANEOUS</h5>
      <p>
        These Terms of Use and any policies or operating rules posted by us on
        the Site or in respect to the Site constitute the entire agreement and
        understanding between you and us. Our failure to exercise or enforce any
        right or provision of these Terms of Use shall not operate as a waiver
        of such right or provision. These Terms of Use operate to the fullest
        extent permissible by law. We may assign any or all of our rights and
        obligations to others at any time. We shall not be responsible or liable
        for any loss, damage, delay, or failure to act caused by any cause
        beyond our reasonable control. If any provision or part of a provision
        of these Terms of Use is determined to be unlawful, void, or
        unenforceable, that provision or part of the provision is deemed
        severable from these Terms of Use and does not affect the validity and
        enforceability of any remaining provisions. There is no joint venture,
        partnership, employment or agency relationship created between you and
        us as a result of these Terms of Use or use of the Site. You agree that
        these Terms of Use will not be construed against us by virtue of having
        drafted them. You hereby waive any and all defenses you may have based
        on the electronic form of these Terms of Use and the lack of signing by
        the parties hereto to execute these Terms of Use.
      </p>
      <h5>TERMINATION</h5>
      <p>
        These Terms will be immediately terminated by discontinuing your use of
        or participate in the Services and you agree to terminate the
        accessibility on the Promodex .
      </p>
      <p>
        These Terms can be suspended or terminated without a notice from the
        Company if there is a reasonable ground for the Company to believe that
        you have breached any of the terms or provisions stipulated in these
        Terms, or if you do not comply with these Terms.
      </p>
      <p>
        The termination of these Terms will not prevent the Company from seeking
        remedies from you in the case where you have breached any terms or
        provisions of these term before such termination. The Company will not
        be liable to you or to any third party for any termination, suspension,
        or modification of your access to the Services.
      </p>

      <p>
        Any ongoing obligation to you as well as the provisions regarding (i)
        Promodex’ Intellectual Property, (ii) No solicitation or Offering, (iii)
        Indemnification, (iv) Limitation of liability, and (v) any other
        provisions designed to survive, will survive any termination or
        expiration of these Terms for any reason.
      </p>
      <p>No Third-Party Rights</p>
      <p>
        Nothing in these Terms will be deemed to create any rights to any
        creditors or other persons, not a party hereto. Moreover, these Terms
        will not be construed, in any respect, to be a contract, in whole or in
        part, for the benefit of any third parties.
      </p>

      <p>Clickwrap</p>
      <p>
        The Company may deliver the Service through electronic means such as
        download links, graphical, Tools or other technologies for providing the
        Service for users. The user interfaces to such electronic means may
        require that the users agree to these Terms by checking a box, clicking
        a button, or continuing with the Service. If user through such action
        the user becomes a Party to these Terms. Such an action of acceptance
        shall be sufficient to bind the users to the terms and conditions herein
        these Terms.
      </p>
      <p>Waiver</p>
      <p>
        The failure of the Company to require or enforce strict performance by
        You of any provision of these Terms or the Company’s failure to exercise
        any right under these Terms shall not be construed as a waiver or
        relinquishment of the Company’s right to assert or rely upon any such
        provision or right in that or any other instance. The express waiver by
        the Company of any provision, condition, or requirement of these Terms
        shall not constitute a waiver of any future obligation to comply with
        such provision, condition or requirement. Except as expressly and
        specifically set forth in these Terms, no representations, statements,
        consents, waivers, or other acts or omissions by the Promodex Team shall
        be deemed a modification of these Terms nor be legally binding.
      </p>
      <h5>CONTACT US </h5>
      <p>
        In order to resolve a complaint regarding the Site or to receive further
        information regarding use of the Site, please contact us at:{' '}
      </p>
      <p>Promodex.io</p>
      <p>legal@promodex.io</p>
      <p>
        Programmatic Promotion Marketplace Create, target, budget your campaign
        Get your message spread by a number of influencers and followers all
        over the world.
      </p>
    </div>
  )
}

export default UserAgreement
