import { useTranslation } from 'react-i18next'
import Road from './Road'
import { GiCheckMark } from 'react-icons/gi'
import { ImRadioUnchecked } from 'react-icons/im'


const roadmap1 = ["Market Research", "Core Team Creation", "Concept Development", "Project Validation", "Corporate Identity", "Explainer Videos", "Seed Fund Raise", "Whitepaper Creation", "One pager Design", "Dashboard UX/UI Design"];
const roadmap2 = ["Website Launch", "Influencer Network Development", "Listing on DEX & CEX Booking", "Platform Launch", "Software Development", "Presale starts", "Strategic Investment starts", "Team Expansion", "Software Development", "Global marketing launch", "Token Swap Function Launch"];
const roadmap3 = ["Presale & VC Funding", "Private & Public Sale", "DEX & CEX Listing", "Staking starts", "Global Expansion", "Influencer Base Development", "Software development"];
const roadmap4 = ["KYC integration", "Algorithmic social media account assessment launch", "Campaign function launch", "Project dashboard launch", "Campaigner dashboard launch", "Promoter dashboard launch"]
const roadmap5 = ["Multi-chain Integration", "Multi Wallets Integration", "Campaign analytics development", "Global marketing", "Influencer base expansion", "Campaigners base expansion"]
const roadmap6 = ["Cross-chain swap integration", "IOS app launch", "Android app launch", "More listing on CEX & DEX", "Global events participation"]

const Roadmap1Ul = () => {
  return (
    <ul>
      {roadmap1.map((item, index) => (
        <li key={index}>
          <GiCheckMark />
          {item}
        </li>
      ))
      }
    </ul>
  )
}
const Roadmap2Ul = () => {
  return (
    <ul>
      {roadmap2.map((item, index) => (
        <li key={index}>
          <GiCheckMark />
          {item}
        </li>
      ))
      }
    </ul>
  )
}
const Roadmap3Ul = () => {
  return (
    <ul>
      {roadmap3.map((item, index) => (
        <li key={index}>
          {index < 2 ? <GiCheckMark /> : <ImRadioUnchecked />}
          {item}
        </li>
      ))
      }
    </ul>
  )
}
const Roadmap4Ul = () => {
  return (
    <ul>
      {roadmap4.map((item, index) => (
        <li key={index}>
          <ImRadioUnchecked />
          {item}
        </li>
      ))
      }
    </ul>
  )
}
const Roadmap5Ul = () => {
  return (
    <ul>
      {roadmap5.map((item, index) => (
        <li key={index}>
          <ImRadioUnchecked />
          {item}
        </li>
      ))}
    </ul>
  )
}
const Roadmap6Ul = () => {
  return (
    <ul>
      {roadmap6.map((item, index) => (
        <li key={index}>
          <ImRadioUnchecked />
          {item}
        </li>
      ))}
    </ul>
  )
}

const Roadmap = () => {
  const { t } = useTranslation();


  return (
    <section id='roadmap' className='roadmap'>
      <div className='roadmapWrapper'>
        <h3>{t('translation:roadmapTitle')}</h3>
        <div className='roadmap-web'>
          <div className='roadmapParts1'>
            <div className='roadmapPart'>
              <h5>{t('translation:roadmapPart1Title')}</h5>
                <Roadmap1Ul />
            </div>
            <div className='roadmapPart'>
              <h5>{t('translation:roadmapPart3Title')}</h5>
              <Roadmap3Ul />
            </div>
            <div className='roadmapPart'>
              <h5>{t('translation:roadmapPart5Title')}</h5>
              <Roadmap5Ul />
            </div>
          </div>
          <Road />
          <div className='roadmapParts1'>
            <div className='roadmapPart'>
              <h5>{t('translation:roadmapPart2Title')}</h5>
              <Roadmap2Ul />
            </div>
            <div className='roadmapPart'>
              <h5>{t('translation:roadmapPart4Title')}</h5>
              <Roadmap4Ul />
            </div>
            <div className='roadmapPart'>
              <h5>{t('translation:roadmapPart6Title')}</h5>
              <Roadmap6Ul />
            </div>
          </div>
        </div>
        <div className='roadmap-mobil'>
          <div className='mobil-parts'>
            <div className='mobilPart'>
              <h5>{t('translation:roadmapPart1Title')}</h5>
              <Roadmap1Ul />
            </div>
            <div className='mobilPart'>
              <h5>{t('translation:roadmapPart2Title')}</h5>
              <Roadmap2Ul />
            </div>
            <div className='mobilPart'>
              <h5>{t('translation:roadmapPart3Title')}</h5>
              <Roadmap3Ul />
            </div>
            <div className='mobilPart'>
              <h5>{t('translation:roadmapPart4Title')}</h5>
              <Roadmap4Ul />
            </div>
            <div className='mobilPart'>
              <h5>{t('translation:roadmapPart5Title')}</h5>
              <Roadmap5Ul />
            </div>
            <div className='mobilPart'>
              <h5>{t('translation:roadmapPart6Title')}</h5>
              <Roadmap6Ul />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Roadmap
