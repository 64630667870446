import React from 'react'

function PartnerCard({ src, name, width=150}) {

  // if mobile conrtorl
  console.log('WINDOW WIDTH', window.innerWidth);
  if (window.innerWidth < 768 && !src) return null;

  return (
    <section className='partners-container__card'>
      {src && (<img src={src} alt={name} width={width} />)}
      
    </section>
  )
}

export default PartnerCard